import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import controls.Di
import controls.Icon
import editor.*
import editor.operations.moveCaretToHome
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import views.HelpPanel
import views.TextStyleToolbar

@Composable
fun TestDoc() {
//    var doc = TestDocs1()
    var doc = TestDoc2()
    val dc = DocContext(doc)

    LaunchedEffect(true) {
        dc.moveCaretToHome()
    }

//    dc.moveCaretToHome()
//    dc.moveCaretDownBlockDOM()

    Div({ classes("container-fluid") }) {
        Di("row") {
            Di("col-1 d-none d-sm-block") {
                Text("...")
            }
            Di("col") {
                RenderDocContent(doc, dc)
            }
            Di("col-1 d-none d-sm-block") {

            }
        }
    }
    Di("position-fixed bottom-0 end-0 p-2 border", {
        style {
            property("z-index", 100)
            cursor("hand")
        }
        attr("data-bs-toggle", "offcanvas")
        attr("data-bs-target", "#helpKeys")
//            onClick {
//
//            }
    }) {
        Icon.Keyboard.render({ style { fontSize(1.5.em) } })
    }
    HelpPanel()

    Di("position-fixed bottom-0", {
        style {
            display(DisplayStyle.Flex)
            property("justify-content", "center")
            property("left", 0)
            property("right", 0)
            paddingBottom(10.px)
            property("z-index", 10000)
        }
    }) {
        TextStyleToolbar(null, doc, dc)
    }
}