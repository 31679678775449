package editor

import Browser
import document.*
import editor.operations.*
import editor.plugins.*
import editor.views.CaretId
import editor.views.FrameMenuData
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import net.sergeych.intecowork.doc.IcwkDocument
import net.sergeych.mp_logger.LogTag
import org.w3c.dom.Element
import tools.smartEmit

object DebugList {
    val domObserver = false
    val domObserverTimeout = true
    val mouseObserver = false
    val docLock = false
    val export = false
    val spellChecker = false
    val undo = false
    val save = false
    val transaction = false
    val docEvents = false
    val replayDisabled = false
    val cleaner = false
}

enum class TransactionMode {
    DEFERRED,
    INSTANT
}

class DocContext(
    internal val doc: Doc,
    val isReplay: Boolean = false
) : LogTag("DCTX"), MouseHandler, CaretSelector<Block> {
    private val _fragmentUpdateFlow = MutableSharedFlow<Fragment>()
//    val fragmentUpdateFlow = _fragmentUpdateFlow.asSharedFlow()
    var lastState: EditorState? = null
    // plugins
    val undoManager = UndoManager(this)
    val mouseObserver = MouseObserver(this, DebugList.mouseObserver)
    val selection = SelectionCRange(this)
    val search = Search(this)
    val exporter = Exporter()
//    val spellChecker: SpellChecker? = null
    val spellChecker: SpellChecker? =
        if (Browser.support(EXTENSION.SPELLCHECKER) && !isReplay) SpellChecker(this, debug = DebugList.spellChecker)
        else null
    var domObserver = DOMObserver(this, debug = DebugList.domObserver)
    val shortcuts = ShortcutManager(this)
    var lastMove = CaretMove()
    var nextTransactionStyle: TextStyle? = null
    var lastSavedChain: DocChain? = null
    var transactionMode = TransactionMode.INSTANT
    val replay = Replay(this, DebugList.replayDisabled || isReplay)
    var replayUndoForce: Boolean = false

    private val _isActive = MutableStateFlow<Boolean>(true)
    val isActive: StateFlow<Boolean> = _isActive.asStateFlow()

    var document: IcwkDocument? = null

//    var rootParagraph: Fragment.Paragraph = _root
//        set(value) {
//            field = value
//            _fragmentUpdateFlow.smartEmit(value)
//        }

//    private val _caretUpdateFlow = MutableSharedFlow<Caret>()
//    val caretUpdateFlow = _caretUpdateFlow.asSharedFlow()

    var caret: Caret? = null
        set(value) {
            val b0: Block? = caret?.let { caretBlockSafe(it) }
            val b1 = caretBlockSafe(value)

//            replay.moveCaret(value)
            field = value

            b1?.let { doc.sendRedrawBlock(it, this) }
            if (b0 != null && b1 != b0) doc.sendRedrawBlock(b0, this)
        }

    override suspend fun caretAtPoint(p: Point, isApproximate: Boolean): Caret? {
        return caretAtPointDOM(p, isApproximate)
    }

    override suspend fun onMouseUp(ev: MouseUpEvent) {
        replay.onMouseUp(ev)

        fun selectRange(down: MouseCaret, up: MouseCaret) {
            down.caret?.let { caretDown ->
                up.caret?.let { caretUp ->
                    getCRange(caretDown, caretUp)?.let {
//                        console.log("SELECT RANGE", it.left.toFullString(), it.right.toFullString())
                        selection.select(it)
                        moveCaret(it.right)
                    } ?: {
                        selection.clear()
                    }
                } ?: run {
                    // we failed to get up caret. It's probably ok
                    console.warn("Failed to get caret at up point x=${up.point.x} y=${up.point.y}")
                }
            } ?: run {
                // we failed to get down caret. It's probably ok
                console.warn("Failed to get caret at down point x=${down.point.x} y=${down.point.y}")
            }
        }

        doc.withLock("mouseup") {
            if (ev.next != ev.down) {
                // it's not a click, it's selection
                if (ev.next.caret != ev.down.caret) selectRange(ev.down, ev.next)
            } else {
                if (ev.isDouble && ev.next == ev.previous) {
                    // it's double click
                    ev.next.caret?.let { newCaret ->
                        wordAtPointDOM(ev.next.point, newCaret)?.let {
                            selection.select(it)
                            moveCaret(it.right)
                        } ?: run {
                            selection.clear()
                        }
                    } ?: run {
                        // we failed to get up caret. It's probably ok
                        console.warn("Failed to get caret at up point x=${ev.next.point.x} y=${ev.next.point.y}")
                    }
                } else {
                    // it's a click
                    ev.down.caret?.let {
                        selection.clear()
                        moveCaret(it)
                    } ?: run {
                        // we failed to get down caret. It's probably ok
                        console.warn("Failed to get caret at down point x=${ev.down.point.x} y=${ev.down.point.y}")
                    }
                }
            }
        }
    }

    override suspend fun onMouseDown(ev: MouseDownEvent) {

    }

    override suspend fun onContextMenu(ev: MouseContextMenuEvent) {
        doc.withLock("contextmenu") {
            transactionMode = TransactionMode.DEFERRED
            domObserver.waitAll("onContextMenu")
            val point = ev.caret.point
            val elements = window.document.elementsFromPoint(point.x, point.y)

            val link = (ev.caret.caret ?: caret)?.let {
                val span = caretSpan(it)
                if (span.isLink()) span
                else null
            }

            val menu = ContextMenuData(
                spellChecker?.getContextMenu(ev, elements),
                selection.getSelectionMenu(ev, elements),
                getFrameMenu(ev, elements),
                link
            )

            openContextMenu(this, menu, ev.caret.point) {
                transactionMode = TransactionMode.INSTANT
            }
        }
    }

    fun getFrameMenu(ev: MouseContextMenuEvent, elements: Array<Element>): FrameMenuData? {
        val frameEl = elements.find { it.hasAttribute("frame-guid") }

        val frame = frameEl?.getAttribute("frame-guid")?.let { guid ->
            ev.caret.caret?.let { c ->
                val f = get(c.blockId)?.find(guid)
                if (f != null) f as Fragment.Frame
                else null
            }
        }

        if (frame == null) return null

        return FrameMenuData(frame)
    }

    fun setNextTransactionStyle(style: TextStyle) {
        nextTransactionStyle = style
    }

    fun resetNextTransactionStyle() {
        nextTransactionStyle = null
    }

    /**
     * Show or hide caret. When called from top-level editor functions it might be better
     * to call [displayCaret] instead. It redraws the block, and this state only emits
     * fragment update (which might be enough, we should check it better_.
     */
    var showCaret: Boolean = true
        set(value) {
            if (field != value) {
                field = value
                caret?.let { c ->
                    caretBlockSafe(c)?.let {
                        it.find(c.spanId)?.let {
                            _fragmentUpdateFlow.smartEmit(it)
                        }
                    }
                }
            }
        }

    /**
     * High-level display/hide caret. Check the state should be changed and sends
     * also redraw block properly. After this function current will actually be shown/hidden and
     * the doc redrawn. This is usually preferred way to change cursor visibility.
     */
    fun displayCaret(show: Boolean) {
        if( show != showCaret) {
            showCaret = show
            caretBlockSafe(caret)?.let {
                doc.sendRedrawBlock(it, this)
            }
        }
    }

    var defaultTextStyle: TextStyle? = null
        private set

    /**
     * All named styles this document uses. Predefined and (later) user ones.
     */
    private fun namedStyles() = listOf(
        ParagraphStyle.heading, ParagraphStyle.subheading,
        ParagraphStyle.heading1,
        ParagraphStyle.heading2,
        ParagraphStyle.heading3,
        ParagraphStyle.heading4,
        ParagraphStyle.normal,
    )

    /**
     * Get the named style of the document or null if it is not (yet?) defined.
     * In the latter case we might have to add it to the context's collection.
     */
    fun getNamedStyle(name: String?): ParagraphStyle? = namedStyles().firstOrNull() { it.name == name }

    fun setIsActive(isActive: Boolean) {
        _isActive.value = isActive
    }

    fun <T> withCaret(f: (Caret) -> T): T {
        val c = caret ?: throw Exception("caret required")
        return f(c)
    }

    fun <T> ensureCaret(c: Caret? = caret, f: (Caret) -> T): T {
        if (c == null) throw Exception("caret required")

        return f(c)
    }

    suspend fun <T> withAsyncCaret(f: suspend (Caret) -> T): T {
        val c = caret ?: throw Exception("caret required")
        return f(c)
    }

    fun caretBlock(c: Caret? = caret): Block {
        return ensureCaret(c) {
//            console.log("access to doc 2")
            doc[it.blockId]
        }
    }

    private fun caretBlockSafe(c: Caret?): Block? {
        return ensureCaret(c) { doc.safeGet(it.blockId) }
    }

    fun caretAtNextBlock(c: Caret?): Caret? {
        return ensureCaret(c) {
            val nextBlock = doc.nextBlockFocusable(caretBlock(c))

            nextBlock?.caretAtStart()
        }
    }

    fun caretAtPrevBlock(c: Caret?): Caret? {
        return ensureCaret(c) {
            val prevBlock = doc.prevBlockFocusable(caretBlock(c))

            prevBlock?.caretAtEnd()
        }
    }

    override fun get(guid: String): Block? {
        return doc.safeGet(guid)
    }

    override fun caretLeft(c: Caret?): Caret? {
        return ensureCaret(c) { caretLeftBlockWide(it) ?: caretAtPrevBlock(c) }
    }

    override fun caretRight(c: Caret?): Caret? {
        return ensureCaret(c) { caretRightBlockWide(it) ?: caretAtNextBlock(c) }
    }

    override fun caretLeftCellWide(c: Caret?): Caret? {
        return ensureCaret(c) {
            val left = caretBlock(c).caretLeft(it)
            if (left?.cellId != c?.cellId) null
            else left
        }
    }

    override fun caretRightCellWide(c: Caret?): Caret? {
        return ensureCaret(c) {
            val right = caretBlock(c).caretRight(it)
            if (right?.cellId != c?.cellId) null
            else right
        }
    }

    override fun indexOfBlock(guid: String): Int {
        return doc.allBlocks.indexOfFirst { it.guid == guid }
    }

    override fun caretLeftBlockWide(c: Caret?): Caret? {
        return ensureCaret(c) { caretBlock(c).caretLeft(it) }
    }

    override fun caretRightBlockWide(c: Caret?): Caret? {
        return ensureCaret(c) { caretBlock(c).caretRight(it) }
    }

    suspend fun waitCaret(): Boolean {
        return domObserver.wait(CaretId, "waitCaret")
    }

    suspend fun waitBlock(guid: String?): Boolean {
        if (guid == null) return true

        return domObserver.wait(guid, "waitBlock $guid")
    }

    internal val rootParagraph: IParagraph = doc.firstBlock.paragraph
}

