package document

import client
import net.sergeych.intecowork.IcwkClient
import net.sergeych.intecowork.api.ApiUserBlock

abstract class UserBlock<T>(val utag: String, val defaultValue: T, val c: IcwkClient = client) {
    abstract suspend fun merge(source: T, their: T, our: T): T
    abstract suspend fun unpack(data: ByteArray): T
    abstract suspend fun pack(data: T): ByteArray

    var data: T? = null

    suspend fun load() {
        val block = c.userBlockGet(utag)
        if (block == null) {
            create(defaultValue)
            data = defaultValue
        }
        else data = unpack(block.data)
    }

    suspend fun get(): ApiUserBlock? {
        return c.userBlockGet(utag)
    }

    suspend fun getData(): T? {
        return get()?.data?.let { unpack(it) }
    }

    suspend fun create(newData: T) {
        c.userBlockCreate(utag, pack(newData))
        data = newData
    }

    suspend fun delete() {
        c.userBlockDelete(utag)
        data = null
    }

    suspend fun update(updatedData: T) {
        val existing = get() ?: throw BugException("Can't update non-existent user block")
        val existingData = unpack(existing.data)
        val merged = merge(existingData, existingData, updatedData)
        val updated = ApiUserBlock(utag, pack(merged), existing.serial)
        val isUpdated = c.userBlockUpdate(updated) != null

        if (isUpdated) data = merged
        else update(updatedData)
    }
}