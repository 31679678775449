package views

import androidx.compose.runtime.Composable
import editor.plugins.SHORTCUT_GROUPS
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.colspan
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTableCellElement
import tools.csplit

@Composable
fun Tdn(attrs: AttrBuilderContext<HTMLTableCellElement>? = null, builder: ContentBuilder<HTMLTableCellElement>) {
    Td({
        attrs?.invoke(this)
        style { whiteSpace("nowrap") }
        classes("pe-2")
    }, builder)
}
@Composable
fun HelpPanel() {
    val isOSX = window.navigator.platform.indexOf("Mac") >= 0
    val groups = SHORTCUT_GROUPS.drop(1)


    Div({
        id("helpKeys")
        classes("offcanvas offcanvas-end p-2".csplit)
        attr("data-bs-keyboard", "true")
        style {
            property("overflow-y", "scroll")
        }
    }) {
        Table {
            Tbody {
                groups.forEach { g ->
                    Tr {
                        Td({ colspan(2); classes("help_header") }) {
                            Text(g.name)
                        }
                    }

                    g.shortcuts.forEach { s ->
                        Tr {
                            Td { Text(s.name) }
                            Tdn { Text(s.combo.getName(isOSX)) }
                        }
                    }
                }

//                Tr {
//                    Td({ colspan(2); classes("help_header") }) {
//                        Text("Навигация")
//                    }
//                }
//
//                Tr {
//                    Td { Text("в начало абзаца") }
//                    Tdn { Text("$metaKeyName/Ctrl + ←") }
//                }
//                Tr {
//                    Td { Text("в конец абзаца") }
//                    Tdn { Text("$metaKeyName/Ctrl + →") }
//                }
//                Tr {
//                    Td { Text("к предыдущему абзацу") }
//                    Tdn { Text("$metaKeyName/Ctrl + ↑") }
//                }
//                Tr {
//                    Td { Text("к следующему абзацу") }
//                    Tdn { Text("$metaKeyName/Ctrl + ↓") }
//                }
//
//                Tr {
//                    Td({ colspan(2); classes("help_header") }) {
//                        Text("Стили параграфа")
//                    }
//                }
//
//                Tr {
//                    Td { Text("Заголовок 1..4") }
//                    Tdn { Text("$winCtrlOrMetaName + $altKeyName + 1..4") }
//                }
//                Tr {
//                    Td { Text("Обычный текст") }
//                    Tdn { Text("$winCtrlOrMetaName + $altKeyName + 0") }
//                }
//                Tr {
//                    Td { Text("Увеличить отступ") }
//                    Tdn { Text("Tab") }
//                }
//                Tr {
//                    Td { Text("Уменьшить отступ") }
//                    Tdn { Text("Shift + Tab") }
//                }
//                Tr {
//                    Td { Text("Переключить выравнивание") }
//                    Tdn { Text("$winCtrlOrMetaName + J") }
//                }
//
//                Tr {
//                    Td({ colspan(2); classes("help_header") }) {
//                        Text("Редактирование")
//                    }
//                }
//
//                Tr {
//                    Td { Text("удалить слово влево от курсора") }
//                    Tdn { Text("$winCtrlOrMetaName + $backspaceKeyName") }
//                }
//
//                Tr {
//                    Td({ colspan(2); classes("help_header") }) { +"Поиск" }
//                }
//                Tr {
//                    Td { +"Поиск по тексту" }
//                    Tdn { +"Ctrl + F" }
//                }
//                Tr {
//                    Td { +"Поиск с заменой" }
//                    Tdn { +"Ctrl + Shift + F" }
//                }
//
//                Tr {
//                    Td({ colspan(2); classes("help_header") }) {
//                        Text("Прочее")
//                    }
//                }
//
//                Tr {
//                    Td { Text("перерисовать текст, при проблемах отображения") }
//                    Tdn { Text("Ctrl + R") }
//                }
            }
        }
    }
}
