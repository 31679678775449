package editor.operations

import document.Caret
import editor.*
import editor.plugins.SpellCheckerDocumentState
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.intecowork.api.DocState
import net.sergeych.intecowork.doc.IcwkDocument

fun DocContext.getState(): EditorState {
    val scState = spellChecker?.getDocumentState() ?: lastState?.spellchecker

    return EditorState(
        caret?.position(),
        spellchecker = scState
    )
}

suspend fun DocContext.save(document: IcwkDocument) {
    val ctx = this

    doc.withLock("dc.save") {
//        delay(5000)
        if (transactionMode != TransactionMode.INSTANT || !isActive.value) return@withLock

        withAsyncCaret {
            if (doc.isDirty()) doc.save(document, ctx)
            val actualState = getState()
            if (lastState != actualState) {
                document.saveState(DocState.pack(actualState))
                if (lastMove.position != actualState.caretPosition)
                    lastMove = lastMove.copy(position = actualState.caretPosition)
            }
        }
    }
}

suspend fun DocContext.saveState(cd: IcwkDocument? = document) {
    if (cd == null) return

    val actualState = getState()

    if (lastState != actualState) {
        cd.saveState(DocState.pack(actualState))

        if (lastMove.position != actualState.caretPosition)
            lastMove = lastMove.copy(position = actualState.caretPosition)
    }
}

fun DocContext.getInitialCaret(pos: Position?): Caret? {
    if (pos == null) return caretToHome()

    val cBlock = doc.allBlocks.find { it.guid == pos.blockId } ?: return caretToHome()

    val cSpan = cBlock.find(pos.fragmentId) ?: return cBlock.caretAtStart()

    val offset = if (pos.offset > cSpan.lastOffset) cSpan.lastOffset else pos.offset

    return cBlock.caretAt(cSpan.guid, offset)
}

fun ensureSC(exclude: Set<String>?): ByteArray {
    val spellcheckerState = SpellCheckerDocumentState.V1(exclude ?: emptySet())
    val encoded = BossEncoder.encode(spellcheckerState)

    return encoded
}

@Serializable
@SerialName("document.EditorState")
data class EditorState(
    val caretPosition: Position?,
    val isSpellCheckOn: Boolean? = false, // deprecated
    val spellCheckExclude: Set<String>? = emptySet(), // deprecated
    val spellchecker: ByteArray? = ensureSC(spellCheckExclude),
)

suspend fun DocContext.setState(state: EditorState?) {
    val actual = state
    lastState = actual
    val initialCaret = getInitialCaret(actual?.caretPosition)

    lastMove = lastMove.copy(position = initialCaret?.position())
    caret = initialCaret
    lastSavedChain = DocChain(doc.allBlocksCopy, initialCaret)
    replay.init(doc.allBlocksCopy, initialCaret)

    spellChecker?.setDocState(actual?.spellchecker)

    // REFACTOR
//    state?.isSpellCheckOn?.let {
//        spellChecker.turnOn()
//    }

    fixV1()
}
