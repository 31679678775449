import document.*
import editor.views.ImageFormat

typealias Fabricator = (list: List<MergeUnit>) -> MergedFragment?

private var fabricators = emptySet<Fabricator>()

fun registerFabricator(fab: Fabricator) {
    fabricators += fab
}

fun restoreFragments(units: List<MergeUnit>): List<Fragment> {
    val fragments = mutableListOf<Fragment>()
    var list = units
    var mergedFragment: MergedFragment? = null

    do {
        fabricators.find {
            mergedFragment = it(list)
            mergedFragment != null
        }

        mergedFragment?.let {
            list = list.slice(it.source.size until list.size)
            fragments += it.fragment
        }
    } while(list.isNotEmpty() && mergedFragment != null)

    return fragments
}

/**
 *
 */
sealed class MergeUnit {
    abstract val fragment: Fragment
    abstract val version: String?
    abstract val sourceIndex: Int?

    data class StyledSpanChar(
        val char: Char?,
        override val fragment: Fragment,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is StyledSpanChar) return false
            return char == other.char
        }
    }

    data class StyledSpanStyle(
        val style: TextStyle?,
        override val fragment: Fragment,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is StyledSpanStyle) return false
            return style == other.style
        }
    }

    data class FramePosition(
        val type: String,
        val position: FrameFormat.Position,
        override val fragment: Fragment,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is FramePosition) return false

            return type == other.type && position == other.position
        }

        override fun hashCode(): Int {
            var result = type.hashCode()
            result = 31 * result + position.hashCode()
            result = 31 * result + fragment.hashCode()
            result = 31 * result + (version?.hashCode() ?: 0)
            return result
        }
    }

    data class FrameBin(
        val bin: ByteArray,
        override val fragment: Fragment,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is FrameBin) return false

            return bin.contentEquals(other.bin)
        }

        override fun hashCode(): Int {
            var result = fragment.hashCode()
            result = 31 * result + bin.contentHashCode()
            return result
        }
    }

    data class LinkedImageUnit(
        val url: String,
        override val fragment: Fragment,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is LinkedImageUnit) return false
            return url == other.url
        }
    }

    data class StoredImage(
        val bin: ByteArray,
        val format: ImageFormat,
        override val fragment: Fragment,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is StoredImage) return false
            return bin.contentEquals(other.bin) && format == other.format
        }
    }

    data class ParagraphHead(
        val guid: String,
        val paragraphStyle: ParagraphStyle? = null,
        override val fragment: Fragment.Paragraph,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is ParagraphHead) return false
            return guid == other.guid && paragraphStyle == other.paragraphStyle
        }
    }


    data class ParagraphEnd(
        val guid: String,
        override val fragment: Fragment.Paragraph,
        override val sourceIndex: Int? = null,
        override val version: String? = null,
    ): MergeUnit() {
        override fun equals(other: Any?): Boolean {
            if (other !is ParagraphEnd) return false
            return guid == other.guid
        }
    }
}

data class IndexPair(
    val sourceIndex: Int,
    val fragmentIndex: Int
)

data class MergedFragment(
    val fragment: Fragment,
    val source: List<MergeUnit>,
    val unchangedStart: IndexPair? = null,
    val uncnahgedEnd: IndexPair? = null,
)


interface Mergable {
    fun toMergeUnits(version: String? = null): List<MergeUnit>
}