package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableElement
import views.unaryPlus


object PropTableBuilder {

    @Composable
    fun p(name: String,value: String) {
        p(name) { Text(value) }
    }

    @Composable
    fun p(name: String,builder: ContentBuilder<HTMLTableCellElement>) {
        Tr {
            Td({classNames("text-end pe-3 py-2 align-middle fw-bold")}) { +name }
            Td { builder() }
        }
    }
}

@Composable
fun Props(attrs: AttrBuilderContext<HTMLTableElement>?=null, handler: @Composable PropTableBuilder.()->Unit) {
    Table({
        classes("p-2")
        style {
            width(100.percent)
        }
        attrs?.invoke(this)
    }) {
        Tbody {
            PropTableBuilder.handler()
        }
    }
}
