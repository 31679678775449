package editor.operations

import document.Caret
import editor.*
import editor.operations.*
import kotlinx.browser.window
import net.sergeych.mp_logger.warning
import kotlin.math.abs
import kotlin.math.min

suspend fun DocContext.onArrow(lockName: String, isShift: Boolean, nextCaret: (caretBefore: Caret) -> Caret?) {
    doc.withLock(lockName) {
        withAsyncCaret { caretBefore ->
            val caretAfter = nextCaret(caretBefore)

            val sMove = selection.onArrow(isShift, caretBefore, caretAfter)
            if (sMove.canMove) moveCaret(sMove.newCaret ?: caretAfter)
        }
    }
}

suspend fun DocContext.onArrowLeft(isShift: Boolean) {
    replay.onArrowLeft(isShift)
    onArrow("arrow-left", isShift) { caretLeft(it) }
}

suspend fun DocContext.onArrowLeftAlt(isShift: Boolean) {
    replay.onArrowLeftAlt(isShift)
    onArrow("arrow-left-alt", isShift) { caretToNextWord(false) }
}

suspend fun DocContext.onArrowLeftCtrl(isShift: Boolean) {
    replay.onArrowLeftCtrl(isShift)
    onArrow("arrow-left-ctrl", isShift) { caretAtContainerStart() }
}

suspend fun DocContext.onArrowRight(isShift: Boolean) {
    replay.onArrowRight(isShift)
    onArrow("arrow-right", isShift) { caretRight(it) }
}

suspend fun DocContext.onArrowRightAlt(isShift: Boolean) {
    replay.onArrowRightAlt(isShift)
    onArrow("arrow-right-alt", isShift) { caretToNextWord(true) }
}

suspend fun DocContext.onArrowRightCtrl(isShift: Boolean) {
    replay.onArrowRightCtrl(isShift)
    onArrow("arrow-right-ctrl", isShift) { caretAtContainerEnd() }
}

suspend fun DocContext.onArrowUp(isShift: Boolean, isCtrl: Boolean) {
    replay.onArrowUp(isShift, isCtrl)
    doc.withLock("arrow-up") {
        withAsyncCaret { caretBefore ->
            val caretBox = caretBoxDOM(caretBefore) ?: return@withAsyncCaret
            val x = lastMove.x ?: caretBox.center.x

            val caretAfter =
                if (isCtrl) caretUpBlockDOM(x)
                else caretAtYDOM(true, x)

            val sMove = selection.onArrow(isShift, caretBefore, caretAfter)
            if (sMove.canMove) moveCaret(sMove.newCaret ?: caretAfter, x, MoveDirection.UP)
        }
    }
}

suspend fun DocContext.onArrowDown(isShift: Boolean, isCtrl: Boolean) {
    replay.onArrowDown(isShift, isCtrl)
    doc.withLock("arrow-down") {
        withAsyncCaret { caretBefore ->
            val caretBox = caretBoxDOM(caretBefore) ?: return@withAsyncCaret
            val x = lastMove.x ?: caretBox.center.x

            val caretAfter =
                if (isCtrl) caretDownBlockDOM(x)
                else caretAtYDOM(false, x)

            val sMove = selection.onArrow(isShift, caretBefore, caretAfter)
            if (sMove.canMove) moveCaret(sMove.newCaret ?: caretAfter, x, MoveDirection.DOWN)
        }
    }
}

fun getClosestYBoxes(caretBox: Box, boxes: List<FragmentBox>): List<FragmentBox> {
    fun distanceXY(f: FragmentBox) = caretBox.center.distanceTo(f.box)
    fun distanceY(f: FragmentBox) = min(abs(f.box.y0 - caretBox.center.y), abs(f.box.y1 - caretBox.center.y))

    // 1. select 10 closest boxes (XY distance)
    val closestXYBoxes = boxes.sortedBy { distanceXY(it) }.take(10)
    // 2. select closest line, e.g. by the dY (from caret box center to closest border of box)
    val minYDistance = closestXYBoxes.minOf {  distanceY(it) }
    val closestYBoxes = closestXYBoxes.filter { distanceY(it) <= minYDistance }

    return closestYBoxes
}

suspend fun DocContext.caretAtYDOM(isUp: Boolean, x: Double): Caret? {
    return withAsyncCaret { c ->
        val caretBox = caretBoxDOM(c) ?: return@withAsyncCaret null
        val block = caretBlock(c)

//        debug { "caretAtYDOM (${x}): run" }

        // get all acceptable text node boxes in caret block
        val boxes = block.textBoundingBoxesDOM().filter {
            // only styled spans and above/below current
            if (isUp) it.box above caretBox else it.box below caretBox
        }

        // no closest text in caret block, go to next block
        if (boxes.isEmpty()) {
//            debug { "caretAtYDOM (${x}): go to next block" }
            if (isUp) caretUpBlockDOM(x) else caretDownBlockDOM(x)
        } else {

            val closestYBoxes = getClosestYBoxes(caretBox, boxes)

            var caretOffsetCorrection = 0
            var resultCaret: Caret? = null
            var i = 0

            while (i < 2 && i < closestYBoxes.size && resultCaret == null) {
                val fragmentBox = closestYBoxes[i]
//        if (i < 4) {
//            val colors = listOf("green", "yellow", "orange", "red")
//            drawDebugBorder(f.box, colors[i])
//        }
                val text = fragmentBox.node.textContent

                if (text == null) warning { "caretAtYDOM (${x}): fragment with null text content: ${fragmentBox.fragment.guid}" }
                else {
                    /*
                     The problem is, the node could be big and occupy sever lines, and we need
                     only the part of it that fits the f.box. We know no way to effectively
                     limit search to it. so instead, we will create another search point that
                     will be either inside this box or on its left or right edge, so the regular search
                     could produce the offset we need.
                     first, we get our point in the middle of the line occupied by the target box
                     just above or below the caret middle point
                */

//            console.log(">.< set caret to desired point, desiredCaretX=${x} lastDesiredCaretX=${lastMove.x}")
                    var p = Point(x, fragmentBox.box.center.y)

                    // if it is inside the box, we need no correction:
                    if (p !in fragmentBox.box) {
                        /*
                         it is not, then we will shift our point by x axis to the box edge,
                         to make sure no any other part of our node is closer to it than our
                         position to be found:
                    */
                        if (p.x < fragmentBox.box.x0) {
                            // e.g.box to the right from the caret, we move the point to its left edge:
                            p = p.copy(x = fragmentBox.box.x0)
                            lastMove = lastMove.copy(x = null)
                        } else {
                            // if we get there, the box is ti the left, and we get to its right edge:
                            p = p.copy(x = fragmentBox.box.x1)
                            lastMove = lastMove.copy(x = caretBox.center.x)
                        }
                    }

//                    drawDebugBorder(Box(p, 8.0), "blue", 2.0)

                    /*
                 we prepared synthetic search point, the regular offset point should suffice:
                 the bloody hack. we can be left of caret, if we move down we need next one.
                 line with caret alays ends on invisibleNBSP:
                */
                    // FIXME: remove invisible from doc
                    if (text.endsWith(invisibleNBSP) && !isUp) {
                        // remember pre-caret length and remove invisible non-breakable spaces characters from count
                        caretOffsetCorrection = text.length - 2
//                        console.log("text ends with invisible space, fix caretOffset by ${text.length - 2} and do nothing?")
                    } else {
                        // We might need to correct p-point position to viewport. We have no ide how to do it
                        // better than:
                        val fix = Point(0.0, window.scrollY)
                        val newCaret = block.caretAt(
                            fragmentBox.fragment.guid,
                            // important. range of selection in browser often incorporate various borders, so
                            // our limiting box should be larger than the client box we've found. the value is an estimation :(
                            findClosestTextOffset(
                                fragmentBox.node, p - fix,
                                (fragmentBox.box - fix).growBy(1.3)
                            ) + caretOffsetCorrection
                        )


                        if (newCaret == c) {
//                            debug { "caretAtYDOM (${x}): new caret is the same" }
                        } else {
//                            debug { "caretAtYDOM (${x}): new caret ${newCaret}" }
                            resultCaret = newCaret
                        }
                    }
                }

                i++
            }


//            debug { "caretAtYDOM (${x}): i=${i} resultCaret=${resultCaret}" }
//    if (i > 1) throw BugException("failed to find position")

            resultCaret
        }
    }

}