package views

import androidx.compose.runtime.*
import client
import controls.Bn
import controls.Toaster
import controls.classNames
import controls.textField
import kotlinx.browser.document
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement

@Composable
fun Authentication(additionalContent: ContentBuilder<HTMLDivElement>) {
    var loginName by remember { mutableStateOf("testUser") }
    var loginError by remember { mutableStateOf<Boolean>(false) }
    var password by remember { mutableStateOf("12345qwert") }
    var inProgress by remember { mutableStateOf(false) }

//    CenteredNarrowForm {
    Div {
        H3 { Text("Вход:") }
        val loginId = textField(
            loginName,
            "Имя учетной записи 1",
            message = if( loginError ) "не должно содержать пробелов в начале и конце" else null
        ) {
            loginName = it
            loginError = loginName.trim() != loginName
        }
        textField(
            password,
            "Пароль",
            type = InputType.Password,
        ) {
            password = it
        }
        Bn({
            classNames("btn-primary me-2")
            if ( loginError || loginName.isBlank() || password.isBlank() || inProgress)
                disabled()
            onClick {
                inProgress = true
                globalLaunch {
                    if( !client.login(loginName,password) )
                        Toaster.error("неправильный логин и/или пароль")
                    inProgress = false
                }
            }
        }) {
            Text("Вход")
        }
        additionalContent()
        LaunchedEffect("loginFocus") {
            (document.getElementById(loginId) as? HTMLInputElement)?.focus()
        }
    }
}
