package editor.plugins

import editor.invisibleNBSP
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.await
import org.w3c.dom.*
import org.w3c.dom.clipboard.ClipboardEvent
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent

enum class BROWSER {
    FIREFOX,
    OPERA,
    EDGE,
    CHROME,
    SAFARI,
    IE,
    OTHER,
}

enum class EXTENSION {
    CLIPBOARD_READ,
    CLIPBOARD_WRITE,
    SPELLCHECKER
}

fun getBrowser(): BROWSER {
    fun isAgent(agentName: String): Boolean {
        return window.navigator.userAgent.indexOf(agentName) > -1
    }

    if (isAgent("Opera") || isAgent("OPR")) return BROWSER.OPERA
    if (isAgent("Edg")) return BROWSER.EDGE
    if (isAgent("Chrome")) return BROWSER.CHROME
    if (isAgent("Safari")) return BROWSER.SAFARI
    if (isAgent("Firefox")) return BROWSER.FIREFOX
    if (isAgent("MSIE")) return BROWSER.IE

    return BROWSER.OTHER
}

class CrossBrowser {
    val browser = getBrowser()
    val supported = mutableMapOf<EXTENSION, Boolean>()

//    suspend fun isClipboardReadAvailable(): Boolean {
//        return try {
//            window.navigator.clipboard.readText().await()
//            true
//        } catch(e: Throwable) {
//            false
//        }
//    }

    suspend fun init() {
        supported[EXTENSION.SPELLCHECKER] = browser != BROWSER.FIREFOX
//        supported[EXTENSION.CLIPBOARD_READ] = isClipboardReadAvailable()
//        supported[EXTENSION.CLIPBOARD_WRITE] = supported[EXTENSION.CLIPBOARD_READ] ?: false
    }

    suspend fun clipboardReadText(): String? {
        if (supported[EXTENSION.CLIPBOARD_READ] == false) return null

        return try {
            window.navigator.clipboard.readText().await()
        } catch(e: Throwable) {
            supported[EXTENSION.CLIPBOARD_READ] = false
            null
        }
    }

    suspend fun clipboardWriteText(text: String): Boolean {
        if (supported[EXTENSION.CLIPBOARD_WRITE] == false) return false

        return try {
            window.navigator.clipboard.writeText(text).await()
            true
        } catch(e: Throwable) {
            supported[EXTENSION.CLIPBOARD_WRITE] = false
            false
        }
    }

    fun support(ext: EXTENSION): Boolean {
        return supported[ext] ?: false
    }
}

class CrossPaste(val scope: CoroutineScope, val pasteHandler: (plainText: String?, ev: Event?) -> Unit) {
    var isCTRL = false
    var isPasteEventSupported = false
    var pasteCatcher: Element? = null
    var lastEvent: KeyboardEvent? = null
    var observer: MutationObserver? = null

    fun normalize(text: String?) {
        if (text == null) pasteHandler(null, lastEvent)
        else {
            val invisibleRemoved = text.replace("$invisibleNBSP", "")
            val normalized = invisibleRemoved.split('\n').map {
                it.split(' ').map { it.trim() }.filter { it.isNotEmpty() }.joinToString(" ")
            }.filter { it.isNotEmpty() }.joinToString("\n")

            pasteHandler(normalized, lastEvent)
        }
    }

    val onDown = { e: Event ->
        val ke = e as KeyboardEvent

        if (ke.metaKey || ke.ctrlKey) isCTRL = true

        if (ke.code == "KeyV") {
            lastEvent = ke
            val activeElement = window.document.activeElement
            val isTextInput = activeElement?.getAttribute("text") == "text" || activeElement?.tagName == "textarea"

            if (!isTextInput) {
                if (isCTRL && pasteCatcher != null) {
                    (pasteCatcher as HTMLDivElement).focus()
                }
            }
        }
    }

    val onUp = { e: Event ->
        val ke = e as KeyboardEvent

        if (!ke.metaKey || !ke.ctrlKey) isCTRL = false
    }

    fun getText(item: DataTransferItem?, cb: (str: String?) -> Unit) {
        if (item == null || item.kind != "string") {
            cb(null)
        } else {
            item.getAsString {
                if (item.type.indexOf("text/html") != -1) {
                    val span = window.document.createElement("span")
                    span.innerHTML = it
                    cb(span.textContent)
                } else cb(it)
            }
        }
    }

    val onPaste = { e: Event ->
        val ev = e as ClipboardEvent
        isPasteEventSupported = false


        // items get corrupted when run in scope. length from > 0 to 0
//        scope.launch {
            if (ev.clipboardData != null) {
                val items = e.clipboardData?.items

                items?.let {
                    isPasteEventSupported = true
                    e.preventDefault()
                    var copied = ""
                    val total = items.length
                    var i = 0

                    // FIXME: Need to find a way to iterate items with suspend reading, without loosing items itself
                    fun get() {
                        if (i == total) {
                            if(pasteCatcher != null) pasteCatcher?.innerHTML = ""
                            normalize(copied)
                        } else {
                            val item = items[i]

                            getText(item) {
                                it?.let { copied += it }
                                i++
                                get()
                            }
                        }
                    }

                    get()

                } ?: run {
                    if(pasteCatcher != null) pasteCatcher?.innerHTML = ""
                    //wait for DOMSubtreeModified event
                    //https://bugzilla.mozilla.org/show_bug.cgi?id=891247
                }
            }
//        }
    }

    fun init() {
        window.document.addEventListener("keydown", onDown, false)
        window.document.addEventListener("keyup", onUp, false)
        window.document.addEventListener("paste", { e -> onPaste(e) }, false)

        val pc = window.document.createElement("div")
        pc.setAttribute("id", "paste_ff")
        pc.setAttribute("contenteditable", "")
        pc.setAttribute("style", "opacity:0;position:fixed;top:0px;left:0px;width:10px;margin-left:-20px;")

        window.document.body?.appendChild(pc)

        pasteCatcher = pc

        val obs = MutationObserver { mutations, obs ->
            mutations.forEach {
                if (isPasteEventSupported || !isCTRL || it.type != "childList") {
                    // already got data in autoPaste
                } else {
                    if (it.addedNodes.length == 1) {
                        val added = it.addedNodes[0]
                        normalize(added?.textContent)
                        pasteCatcher?.innerHTML = ""
                    }
                }
            }
        }
        val target = window.document.getElementById("paste_ff")

        target?.let {
            obs.observe(target, MutationObserverInit(childList = true, characterData = true, attributes = true))
        }
        observer = obs
    }

    fun terminate() {
        observer?.disconnect()
        window.document.removeEventListener("keydown", onDown, false)
        window.document.removeEventListener("keyup", onUp, false)
        window.document.removeEventListener("paste", { e -> onPaste(e) }, false)
    }
}