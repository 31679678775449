import androidx.compose.runtime.*
import controls.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import net.sergeych.intecowork.api.ApiAgreement
import net.sergeych.intecowork.api.NotFoundException
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.info
import net.sergeych.mp_logger.warning
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Location
import views.*

typealias ModalHandler = @Composable (() -> Unit) -> Unit

object Router : LogTag("ROUTR") {

    val anonymourRe = "/[^#/?]{2,}#[^/#?]{43}".toRegex()

    var regex: Regex? = null
        private set

    var match: MatchResult? = null
        private set

    val defaultTitle = "MyOnlyDocs"

    data class Target(val title: String = defaultTitle, val content: @Composable () -> Unit)

    fun param(index: Int): String? =
        match?.groupValues?.get(index + 1)

    @Suppress("unused")
    fun longParam(index: Int): Long =
        param(index)?.toLong() ?: throw NotFoundException()

    @Suppress("unused")
    fun longParamOrNull(index: Int): Long? =
        kotlin.runCatching { param(index)?.toLong() }.getOrNull()

    val userRouting = listOf(
        "/" to Target { Home() },
        "/testdoc" to Target { TestDoc() },
        "/documents/(\\d+)" to Target { DocumentPage() },
        "/profile" to Target { Profile() }
//        "/(\\d+)?" to Target { DesignedLayout(PageContent.HOME) },
//        "/system" to Target { views.Home() },
//        "/buildings/new" to Target("Здания") { NewBuilding() },
//        "/buildings/(\\d+)" to Target("Зданиe") { EditBuilding() },
//        "/buildings" to Target("Здания") { ShowBuildings() },
//        "/company/buildings/(\\d+)" to Target("Здания" ) { CompanyBuilding() },
//        "/companies/new" to Target("Организация") { NewCompany() },
//        "/companies/(\\d+)(?:/([^#/?]+))?" to Target("Организация") { EditCompany() },
//        "/companies" to Target("Организации") { ShowCompanies() },
//        "/profile" to Target("Профиль") { UserProfile() },
//        "/building_counters/(\\d+)" to Target("Счетчики") { AdminCounters() },
//        "/admin" to Target("Сисадмин") { AdminSwitchboard() },
//        "/people(?:/([^#/?]+))?" to Target("Пользователи") { AdminUsers() },
//        "/househead/(\\d+)(?:/([^#/?]+))?" to Target("Главдом") { HeadHome() },
//        "/chat/(\\d+)?" to Target("Чат с УК") { DesignedLayout(PageContent.CHAT) },
//        "/employee_chats(?:/([^#/?]+))?" to Target("Чат с УК") { EmployeeChats() },
    ).map { (k, v) -> Regex("$k(?:\\?.*)?$") to v }

    val guestRouting = listOf(
        "/" to Target { Home() },
        "/mergetest" to Target { TestMerge() },
        anonymourRe.pattern to Target { GuestVeiwDocument() },
        "/logout" to Target { Logout() },
        "/help_locked" to Target { HelpOnLocked() },
        "/testdoc" to Target { TestDoc() }
    ).map { (k, v) -> Regex(k) to v }

    private var pathFlow = MutableStateFlow(targetFor(document.location))

    init {
        globalLaunch {
            client.userFlow.collect {
                if (it != null)
                    pathFlow.value = targetFor(document.location)
            }
        }
        window.onpopstate = {
//            window.history.go(1)
//            val l = document.location!!
//            tryQuit(l.pathname + l.hash + l.search) {
//                console.log("^^^^^^^^^^^^^^ POPS ${document.location}")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
//                console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
                document.location?.let { loc ->
                    pathFlow.value = targetFor(loc).also {
                        document.title = it.title
                    }
                } ?: warning { "popstate without location: ${it.state}" }
//            }
        }
        document.title = pathFlow.value.title
    }

    private fun targetFor(loc: Location?): Target {
        if (loc == null) return matchPath("/") ?: guestRouting.first().second
        return matchPath(loc.pathname)
            ?: matchPath(loc.pathname + loc.hash)
            ?: guestRouting.first().second
    }

    private fun targetForUrl(url: String): Target {
        return matchPath(url)
            ?: guestRouting.first().second
    }

    private fun matchPath(url: String): Target? {
        info { "selecting content for ${client.currentUser}: $url" }
        val routes = if (client.currentUser != null) userRouting + guestRouting else guestRouting
        for ((re, target) in routes) {
            re.matchEntire(url)?.let {
                match = it
                return target
            }
        }
        return null
    }

    fun push(url: String) {
        tryQuit(url) {
            val t = targetForUrl(url)
            localHistory.add(url)
            if (localHistory.size > 500) localHistory.removeFirst()
            window.history.pushState(url, t.title, url)
            document.title = t.title
            pathFlow.value = t
        }
    }

    private val quitHandlers = mutableMapOf<Int,QuitHandlerRecord>()

    class QuitHandlerRecord(val id: Int,val callback: QuitHandler.() -> Boolean) {
        fun disconnect() {
            quitHandlers.remove(id)
        }

    }
    class QuitHandler(val url: String?,val record: QuitHandlerRecord) {
        fun disconnect() { record.disconnect() }
        fun retry() {
            if (url == null || url == "/") back()
            else push(url)
        }
    }

    private var quitIds = 0

    fun tryQuit(url: String?, f: () -> Unit) {
        for (h in quitHandlers.values.toList()) {
            if( !h.callback.invoke(QuitHandler(url, h) )) return
        }
        f()
    }

    fun quitHandler(f: QuitHandler.() -> Boolean):QuitHandlerRecord {
        return QuitHandlerRecord(quitIds++,f).also { quitHandlers[it.id] = it }
    }

    private val _modalIsVisible = MutableStateFlow(false)

    /**
     * Allow check the state and collect the event that there are modal windows active.
     */
    val modalIsVisible: StateFlow<Boolean> = _modalIsVisible

    private val localHistory = mutableListOf<String>()

    fun back() {
        if (localHistory.removeLastOrNull() == null)
            push("/")
        else
            tryQuit(null) {
                window.history.go(-1)
            }
    }

    fun replace(url: String) {
        tryQuit(url) {
            targetForUrl(url).let {
                window.history.replaceState(url, it.title, url)
                if (localHistory.isNotEmpty()) localHistory.removeLast()
                localHistory.add(url)
                pathFlow.value = it
                document.title = it.title
            }
        }
    }

    private val modalStack = mutableStateListOf<ModalHandler>()

    fun pushModal(modalHandler: ModalHandler) {
        modalStack.add(modalHandler)
        _modalIsVisible.value = true
    }

    @Composable
    fun userContent() {
        var target by remember { mutableStateOf(pathFlow.value) }
        var lastUser by remember { mutableStateOf(client.currentUser) }
        var modalHandler by remember { mutableStateOf<ModalHandler?>(null) }

        RequireAction(ApiAgreement.Action.GuestAccess) {
            target.content()
        }
        Toaster.contents()

        // the logic is complex before modal can cause other modals to modify the stack, so
        // check that we have an active modal:
        modalHandler?.let { handler ->
            // render it and onclose:
            handler {
                // drop current modal, it well cause recompose
                modalHandler = null
                // and remove it from the stack: could be not the last already:
                modalStack.remove(handler)
                _modalIsVisible.value = modalStack.isNotEmpty()
            }
        } ?: run {
            // recompose with no current modal - pick if exists,
            // it will call recompose so code above will work:
            modalHandler = modalStack.lastOrNull()
        }

        LaunchedEffect("collectStatus") {
            client.userFlow.collect {
                if (lastUser != it) {
                    lastUser = it
                    val url = document.location?.let { it.pathname + it.search + it.hash } ?: ""
                    replace(url)
                }
            }
        }

        LaunchedEffect("collectFlow") {
            pathFlow.collect { target = it }
        }
//        LaunchedEffect(true) {
//            console.log("launched: DDF")
//            delay(500)
//            withConfirm("А правда это надо?") {
//                console.log("Правда!")
//                ShowSecret("12312312lkjhlkj")
//            }
//        }

    }


    suspend fun okDialog(f: DialogScope.() -> Unit) = okDialog(false, f)
    suspend fun <T> okDialog(result: T, f: DialogScope.() -> Unit): T? {
        val d = CompletableDeferred<T>()
        try {
            pushModal { doClose ->
                Dialog {
                    f()
                    footer {
                        Bn({
                            classNames("btn-primary")
                            onClick {
                                d.complete(result)
                                close()
                            }
                        }) {
                            Text("OK")
                        }
                    }
                    onClose { doClose() }
                }
            }
        } catch (t: Throwable) {
            t.printStackTrace()
            d.completeExceptionally(t)
        }
        return d.await()
    }


    @Suppress("unused")
    fun queryParam(name: String): String? =
        Regex("[?&]$name=([^&]+)").find(window.location.search)?.groups?.get(1)?.value
}

fun modalDialg(title: String? = null, f: DialogScope.() -> Unit) {
    try {
        Router.pushModal { doClose ->
            Dialog(title) {
                f()
                onClose(doClose)
            }
        }
    } catch (t: Throwable) {
        console.error("ошибка при создании модального диалога: $t")
        t.printStackTrace()
    }
}

fun confirm(
    text: String, title: String? = null,
    yesText: String = "Да",
    noText: String = "No",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    iconVariant: Variant = Variant.Primary,
    icon: Icon? = null,
): Deferred<Boolean> {
    val done = CompletableDeferred<Boolean>()
    try {
        Router.pushModal { doClose ->
            Dialog {
                title?.let {
                    heading(it)
                }
                body {
                    Di("container-fluid") {
                        Row({
                            if (iconVariant != Variant.Primary)
                                classes(iconVariant.textClass)
                        }) {
                            icon?.let { ic ->
                                Di("col-auto") {
                                    ic.render({ classes("fs-1") })
                                }
                            }
                            Di("col") { Text(text) }
                        }
                    }
                }
                footer {
                    Bn({
                        classes(yesVariant.buttonClass)
                        onClick {
                            close()
                            done.complete(true)
                        }
                    }) {
                        Text(yesText)
                    }
                    Bn({
                        classes(noVariant.buttonClass)
                        onClick {
                            close()
                            done.complete(false)
                        }
                    }) {
                        Text(noText)
                    }
                }
                onClose {
                    if (done.isActive) done.complete(false)
                    doClose()
                }
            }
        }
    } catch (t: Throwable) {
        t.printStackTrace()
        done.completeExceptionally(t)
    }
    return done
}

@Suppress("unused")
fun withConfirm(
    text: String, title: String? = null,
    yesText: String = "Да",
    noText: String = "Нет",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    icon: Icon? = null,
    iconVariant: Variant = Variant.Primary,
    onConfirmed: () -> Unit,
) {
    globalLaunch {
        confirm(text, title, yesText, noText, yesVariant, noVariant, iconVariant, icon).await().let {
            if (it) onConfirmed()
        }
    }
}

@Suppress("unused")
suspend fun waitConfirm(
    text: String, title: String? = null,
    yesText: String = "Да",
    noText: String = "No",
    yesVariant: Variant = Variant.Primary,
    noVariant: Variant = Variant.Secondary,
    iconVariant: Variant = Variant.Primary,
    icon: Icon? = null,
): Boolean {
    return confirm(text, title, yesText, noText, yesVariant, noVariant, iconVariant, icon).await()
}

