package editor.plugins

import document.Caret
import editor.Point
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.w3c.dom.Element
import org.w3c.dom.events.MouseEvent
import kotlin.time.Duration.Companion.milliseconds

// it can be double click or not
val MOUSE_DOUBLE_CLICK_TIMEOUT = 1000.milliseconds
// if points are closer than this, we think it's click at the same point
const val MOUSE_MIN_DISTANCE = 5

interface ContextMenuAvailable {
    fun getContextMenu(ev: MouseContextMenuEvent, pointElements: Array<Element>)
}

data class MouseCaret(
    val point: Point,
    val caret: Caret?
) {
    fun equals(other: MouseCaret?): Boolean {
        if (other == null) return false

        return pointEquals(other.point)
    }

    fun pointEquals(p: Point): Boolean {
        return p.distanceTo(point) < MOUSE_MIN_DISTANCE
    }
}

data class MouseUpEvent(
    val isDouble: Boolean,
    val previous: MouseCaret?,
    val next: MouseCaret,
    val down: MouseCaret
)

data class MouseDownEvent(
    val isDouble: Boolean,
    val previous: MouseCaret?,
    val next: MouseCaret
)

data class MouseContextMenuEvent(
    val caret: MouseCaret
)

interface MouseHandler {
    suspend fun caretAtPoint(p: Point, isApproximate: Boolean): Caret?
    suspend fun onMouseUp(ev: MouseUpEvent)
    suspend fun onMouseDown(ev: MouseDownEvent)
    suspend fun onContextMenu(ev: MouseContextMenuEvent)
}

class MouseObserver(val handler: MouseHandler, val debug: Boolean = false) {
    var lastMouseDownAt: Instant? = null
    var lastMouseUpAt: Instant? = null
    var lastUp: MouseCaret? = null
    var lastDown: MouseCaret? = null

    suspend fun calculateNew(p: Point): MouseCaret {
        return MouseCaret(p, handler.caretAtPoint(p, true))
    }

    fun isDoubleEvent(isDown: Boolean): Boolean {
        val now = Clock.System.now()
        val lastTime = if (isDown) lastMouseDownAt else lastMouseUpAt

        if (isDown) {
            lastMouseDownAt = now
        } else {
            lastMouseUpAt = now
        }

        return lastTime != null && now - lastTime <= MOUSE_DOUBLE_CLICK_TIMEOUT
    }

    suspend fun onEvent(mev: MouseEvent) {
        when (mev.type) {
            "mouseup" -> update(mev, false)
            "mousedown" -> update(mev, true)
            "contextmenu" -> onContextMenu(mev)
            else -> {}
        }
    }

    suspend fun onContextMenu(mev: MouseEvent) {
        mev.preventDefault()

        val point = Point(mev.clientX.toDouble(), mev.clientY.toDouble())
        handler.onContextMenu(MouseContextMenuEvent(calculateNew(point)))
    }

    suspend fun update(mev: MouseEvent, isDown: Boolean) {
        val isDouble = isDoubleEvent(isDown)
        val point = Point(mev.clientX.toDouble(), mev.clientY.toDouble())

        if (!isDouble) {
            if (isDown) lastDown = null else lastUp = null
        }

        val lastEvent = if (isDown) lastDown else lastUp
        val shouldCalculateNew = lastEvent == null || !lastEvent.pointEquals(point)
        val newEvent =  if (shouldCalculateNew) calculateNew(point) else lastEvent as MouseCaret

        if (isDown) lastDown = newEvent else lastUp = newEvent

        if (isDown) {
            val result = MouseDownEvent(
                isDouble,
                lastEvent,
                newEvent
            )

            handler.onMouseDown(result)
        } else {
            val ld = lastDown
            if (ld == null || (isDouble && lastEvent == null)) {
                if (ld == null) console.warn("Got mouseup event, but mousedown not found")
                if (isDouble && lastEvent == null) {
                    console.warn("Got double mouseup event, but first mouseup not found")
                    handler.onMouseUp(MouseUpEvent(false, lastEvent, newEvent, newEvent))
                }
            } else {
                handler.onMouseUp(MouseUpEvent(isDouble, lastEvent, newEvent, ld))
            }
        }
    }
}