package views

import DocsCache
import Router
import androidx.compose.runtime.*
import client
import controls.*
import document.Doc
import document.ParagraphStyle
import editor.DocContext
import editor.operations.caretToHome
import editor.operations.getState
import editor.plugins.importBlocks
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.BlockType
import net.sergeych.intecowork.api.DocState
import net.sergeych.intecowork.api.DocType
import net.sergeych.intecowork.api.InternalError
import net.sergeych.intecowork.doc.DocBlock
import net.sergeych.intecowork.doc.IcwkDocument
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.colspan
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.files.File
import tools.randomId
import tools.storedParam
import tools.toDataSize
import withConfirm
import kotlin.js.Promise

suspend fun createDocument() {
//    Toaster.launchCatching {
        val doc = Doc {
            p(ParagraphStyle.heading) {
                t("")
            }
        }
        val dc = DocContext(doc)
        dc.caret = dc.caretToHome()

        val newDoc = IcwkDocument.create(client, DocType.Text, DocState.pack(dc.getState()))
        newDoc.modify {
            put(DocBlock.pack(BlockType.Body, guid = doc.firstBlock.guid) {
                doc.firstBlock
            })
        }
        newDoc.mergeAll()
        Router.push("/documents/${newDoc.docId}")
//    }
}

fun docxToJSON(file: File): Promise<dynamic> {
    val json = js("docxtojson.default(file)")

    return json
}

suspend fun importDOCX(file: File) {
    val json = docxToJSON(file).await()
    val blocks = importBlocks(json)
    val doc = Doc()
    doc.initializeWithBlocks(blocks)
    doc.calculateViewProperties()
    val dc = DocContext(doc)
    dc.caret = dc.caretToHome()
    val newDoc = IcwkDocument.create(client, DocType.Text, DocState.pack(dc.getState()))
    newDoc.modify {
        doc.allBlocks.forEach {
            put(DocBlock.pack(BlockType.Body, guid = it.guid, prev = it.prevBlockGuid, next = it.nextBlockGuid) {
                it
            })
        }
    }
    newDoc.mergeAll()
    newDoc.setTitle(file.name)
    Router.push("/documents/${newDoc.docId}")
}

@Composable
fun Directory() {
    val scope = rememberCoroutineScope()
    var storedMode: Int? by storedParam<Int>()
    var selectedMode by remember { mutableStateOf(storedMode ?: 0) }
    var sortBy by remember { mutableStateOf(2) }
    var sortReverse by remember { mutableStateOf(false) }
    val list = mutableStateListOf<IcwkDocument>()

    var importDOCXFile: File? by remember { mutableStateOf(null) }
    val importFileCtrlId = remember { randomId(11) }

    fun compare(a: IcwkDocument,b: IcwkDocument): Int {
        val cmp = when(sortBy) {
            0 -> a.titleOrDefault.compareTo(b.titleOrDefault)
            1 -> a.lastCloudSize.compareTo(b.lastCloudSize)
            2 -> a.updatedAt.compareTo(b.updatedAt)
            else -> throw Exception("invalid sort index")
        }
        return if( (sortBy == 0 && sortReverse) || (sortBy != 0 && !sortReverse) ) -cmp else cmp
    }

    fun resort() {
        list.sortWith(::compare)
    }

    LaunchedEffect(selectedMode) {
        val source = when (selectedMode) {
            0 -> DocsCache.allFlow
            1 -> DocsCache.myFlow
            2 -> DocsCache.sharedFlow
            3 -> DocsCache.trashedFlow
            else -> throw InternalError("недопустимы индекс группы файлов")
        }
        source.collect { src ->
            list.clear()
            list.addAll(src.sortedWith(::compare))
        }
    }

    @Composable
    fun ListHeader(sortIndex: Int,text: String, colSpan: Int=1,showSince: String?=null,align: String="center") {
        Td({
            if( colSpan > 1 ) colspan(colSpan)
            style {
                cursor("pointer")
                whiteSpace("nowrap")
            }
            classes("text-$align", "px-1")
            showSince?.let { classes("d-none", "d-$it-table-cell")}
            onClick {
                if( sortIndex == sortBy ) sortReverse = !sortReverse
                else {
                    sortBy = sortIndex
                    sortReverse = false
                }
                resort()
            }
        }) {
            Text(text)
            if(sortIndex == sortBy) {
                if( !sortReverse ) Icon.SortUp.render(1.em)
                else Icon.SortDown.render(1.em)
            }
        }
    }

    Heading("документы", true) {
        Input(InputType.File) {
            attr("id", importFileCtrlId)
            style { display(DisplayStyle.None) }
            // FIXME: need to set value
            onChange {
                scope.launch {
                    val file = it.target.files?.asList()?.firstOrNull()

                    console.log("on change")

                    file?.let {
                        Toaster.launchCatching(onFinally = { importDOCXFile = null }) {
                            importDOCXFile = it
                            console.log("file set")
                            importDOCX(it)
                        }
                    }
                }
            }
        }
        Row {
            Di("col mt-1") {
                Select({
                    classes("form-select")
                    onChange {
                        selectedMode = it.value!!.toInt()
                        storedMode = selectedMode
                    }
                }) {
                    Option("0", { if( selectedMode == 0)  selected()}) { Text("Показать все") }
                    Option("1", { if( selectedMode == 1)  selected()}) { Text("Мои документы") }
                    Option("2", { if( selectedMode == 2)  selected()}) { Text("Где я соавтор") }
                    Option("3", { if( selectedMode == 3)  selected()}) { Text("Удаленные") }
                }
            }
            Di("col-auto mt-1") {
                Btn(
                    "создать",
                    Icon.RichText,
                    variant = Variant.Success,
                ) {
                    scope.launch {
                        createDocument()
                    }
                }

                Btn(
                    "импорт .docx",
                    Icon.RichText,
                    variant = Variant.Success,
                    ms = 2
                ) {
                    console.log("Run click import")
                    window.document.getElementById(importFileCtrlId)?.let {
                        // FIXME: sometimes doesn't work in chrome. After chrome reopen it usually works
                        (it as HTMLInputElement).click()
                    }
                }
            }
        }
    }
    Row {
        Col("12", { classes("mt-2") }) {
//            TabsHeader(selectedMode, listOf("все", "мои", "чужие", "корзина")) {
//                selectedMode = it
//            }
            importDOCXFile?.let {
                WaitPanel("Импортирую ${it.name}")
            }
            if (list.isEmpty()) {
                if (DocsCache.loading) {
                    WaitPanel("загружаю список документов")
                } else {
                    Di("mt-3 alert alert-secondary") {
                        Text("В данный момент таких документов нет. ")
                        when (selectedMode) {
                            0, 1 -> Text("Создайте документ при помощи кнопки \"создать\" выше.")
                            2 -> Text(
                                """
                            Здесь отображаются документы, к которым Вам предоставили доступ другие участники.
                            Сейчас документы в совместном доступе отсутствуют.""".trimIndent()
                            )

                            3 -> Text(
                                """
                            Это корзина, здесь появляются документы, которые Вы удаляете. 
                        """.trimIndent()
                            )
                        }
                    }
                }
            } else {
                ResponsiveTable {
                    Tr({ classes("bg-light", "fs-6") }) {
                        Td{}
                        ListHeader(0,"название", align = "left")
                        ListHeader(1,"размер")
                        ListHeader(2,"изменён", showSince = "sm")
                        Td {}
                    }
                    for (d in list) {
                        Tr({
                            style { cursor("pointer") }
                            onClick { Router.push("/documents/${d.docId}") }
                        }) {
                            Td({
                                style { width(1.percent) }
                            }) {
                                Icon.RichText.render(1.3.em)
                            }
                            Td {
                                Text(d.titleOrDefault)
                            }
                            Td({
                                classes("pe-2")
                                style {
                                    width(1.percent)
                                    whiteSpace("nowrap")
                                    textAlign("right")
                                }
                            }) {
                                Text(d.lastCloudSize.toDataSize())
                            }
                            Td({
                                style {
                                    width(1.percent)
                                    whiteSpace("nowrap")
                                }
                                classes("d-none", "d-sm-table-cell")
                            }) {
                                RelativeTime(d.updatedAt, showDateOnDays = true)
//                                Text(d.updatedAt.shortString())
                            }
                            Td({
                                style {
                                    width(1.percent)
                                    whiteSpace("nowrap")
                                }
                            }) {
                                if (selectedMode != 3) {
                                    BtnLink(
                                        icon = Icon.Recycle, variant = Variant.Danger,
                                        tip = "в корзину"
                                    ) {
                                        withConfirm(
                                            if (d.role.isOwner)
                                                """
                                            Вы действительно хотите переместить документ "${d.titleOrDefault}
                                            в корзину?
                                        """".trimIndent()
                                            else """
                                            Вы действительно хотите удалить доступ к общему документу "${d.titleOrDefault}?
                                            Это действие может отменить только владелец, документа вновь пригласив Вас.
                                            
                                        """.trimIndent(),
                                            yesVariant = Variant.Danger,
                                            icon = Icon.Recycle
                                        ) {
                                            console.log("deleting")
                                            Toaster.launchCatching {
                                                d.moveToTrash()
                                                info("Документ перемещен в корзину")
                                            }
                                        }
                                    }
                                } else {
                                    BtnLink(icon = Icon.Recycle, variant = Variant.Success, tip = "восстановить") {
                                        withConfirm(
                                            """
                                            Вы действительно хотите восстановить документ "${d.titleOrDefault}"
                                            из корзины?
                                        """.trimIndent(),
                                            yesVariant = Variant.Success,
                                            icon = Icon.Recycle
                                        ) {
                                            console.log("restoring")
                                            Toaster.launchCatching {
                                                d.restoreFromTrash()
                                                info("Документ восстановлен из корзины")
                                            }
                                        }
                                    }
                                    BtnLink(icon = Icon.Trash, variant = Variant.Danger, tip = "уничтожить") {
                                        withConfirm(
                                            """
                                            Вы действительно хотите необратимо уничтожить документ "${d.titleOrDefault}"
                                            и освободить занимаемое им место?
                                            """.trimIndent(),
                                            yesVariant = Variant.Danger,
                                            icon = Icon.Trash,
                                            iconVariant = Variant.Danger
                                        ) {
                                            console.log("destroying")
                                            Toaster.launchCatching {
                                                d.erase()
                                                info("Документ уничтожен")
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

val IcwkDocument.titleOrDefault: String
    get() = title ?: "без имени ${docId}"