package editor.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import controls.Di
import controls.Icon
import controls.classNames
import document.Caret
import document.Fragment
import document.TextStyle
import editor.DOCUMENT_CONTROL_CLASS
import editor.DocContext
import editor.RenderMode
import editor.operations.tableAddCol
import editor.operations.tableAddRow
import editor.operations.tableDeleteCol
import editor.operations.tableDeleteRow
import editor.plugins.domVersion
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.Scope
import org.jetbrains.compose.web.attributes.scope
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

@Composable
fun TableColMenu(dc: DocContext, tp: Fragment.TableParagraph, rowIndex: Int, colIndex: Int) {
    val scope = rememberCoroutineScope()

    Di("btn-group $DOCUMENT_CONTROL_CLASS", {
        attr("role", "group")
        style {
            position(Position.Absolute)
            top(-20.px)
            left(0.percent)
            right(0.percent)
            width(80.px)
            property("margin", "0 auto")
        }
    }) {
        Button({
            type(ButtonType.Button)
            classNames("btn btn-secondary dropdown-toggle btn-sm")
            attr("data-bs-toggle", "dropdown")
            attr("aria-expanded", "false")
        }) {
            Text("Формат")
        }

        Ul({
            classes("dropdown-menu")
        }) {
            Li {
                A(null, {
                    classNames("dropdown-item $DOCUMENT_CONTROL_CLASS")
                    onClick { scope.launch { dc.tableAddCol(colIndex, tp.guid) } }
                }) { Text("Добавить столбец слева") }
                A(null, {
                    classNames("dropdown-item $DOCUMENT_CONTROL_CLASS")
                    onClick { scope.launch { dc.tableAddCol(colIndex+1, tp.guid) } }
                }) { Text("Добавить столбец справа") }
                A(null, {
                    classNames("dropdown-item $DOCUMENT_CONTROL_CLASS")
                    onClick { scope.launch { dc.tableDeleteCol(colIndex, tp.guid) } }
                }) { Text("Удалить столбец") }
            }
        }
    }
}

@Composable
fun TableRowMenu(dc: DocContext, tp: Fragment.TableParagraph, rowIndex: Int, colIndex: Int) {
    val scope = rememberCoroutineScope()

    Di("btn-group $DOCUMENT_CONTROL_CLASS", {
        attr("role", "group")
        style {
            position(Position.Absolute)
            left(-17.px)
            top(0.percent)
            bottom(0.percent)

            property("margin", "auto 0")
        }
    }) {
        Button({
            type(ButtonType.Button)
            classNames("btn btn-secondary btn-sm")
            attr("data-bs-toggle", "dropdown")
            attr("aria-expanded", "false")
            style {
                width(17.px)
                paddingLeft(0.px)
                paddingRight(0.px)
            }
        }) {
            Icon.ThreeDotsVertical.render({ style { fontSize(1.2.em) } })
        }

        Ul({
            classes("dropdown-menu")
        }) {
            Li {
                A(null, {
                    classNames("dropdown-item $DOCUMENT_CONTROL_CLASS")
                    onClick { scope.launch { dc.tableAddRow(rowIndex, tp.guid) } }
                }) { Text("Добавить строку выше") }
                A(null, {
                    classNames("dropdown-item $DOCUMENT_CONTROL_CLASS")
                    onClick { scope.launch { dc.tableAddRow(rowIndex+1, tp.guid) } }
                }) { Text("Добавить строку ниже") }
                A(null, {
                    classNames("dropdown-item $DOCUMENT_CONTROL_CLASS")
                    onClick { scope.launch { dc.tableDeleteRow(rowIndex, tp.guid) } }
                }) { Text("Удалить строку") }
            }
        }
    }
}

@Composable
fun renderTableParagraph(
    p: Fragment.TableParagraph,
    attrBuilderContext: AttrBuilderContext<HTMLDivElement>?,
    caret: Caret?,
    style: TextStyle?,
    dc: DocContext,
    mode: RenderMode,
    blockVersion: Int? = null
) {
//    console.warn("RENDER TABLE", caret)
    val caretCoords = p.getCaretCoords(caret)

    Table({
        classes("table","table-hover","table-bordered")
        id(p.guid)
        attr("data-bv", "$blockVersion")
        domVersion(dc.domObserver, p.guid)
        style {
            property("z-index", "2")
        }
    }) {
        Thead {
            if (p.hasHeader) Tr {
                (0..(p.colsCount - 1)).forEach {
                    val isActiveCol = it == caretCoords?.second

                    Th({
                        scope(Scope.Col)
                        classNames("table-header-col")
                        style {
                            if (isActiveCol) position(Position.Relative)
                        }
                    }) {
                        if (mode.isInteractive && isActiveCol && caretCoords?.first == 0) TableColMenu(dc, p, 0, it)

                        if (mode == RenderMode.DOCX) B { renderParagraph(
                            p.elements[it] as Fragment.Paragraph,
                            null,
                            caret,
                            null,
                            dc,
                            mode
                        ) }
                        else Div({ classes("table-header") }) {
                            renderParagraph(
                                p.elements[it] as Fragment.Paragraph,
                                null,
                                caret,
                                null,
                                dc,
                                mode
                            )
                        }
                    }
                }
            }
        }

        Tbody {
            val startRow = if (p.hasHeader) 1 else 0

            (startRow..(p.rowsCount - 1)).forEach { rowIndex ->
//                val activeClass = if (caretCoords?.first == rowIndex) " table-active" else ""

                Tr({
//                    classNames("table-data-row${activeClass}")
                }) {
                    ((rowIndex * p.colsCount) until (rowIndex*p.colsCount + p.colsCount)).forEach {
                        val colIndex = it % p.colsCount
                        val isActiveRow = caretCoords?.first == rowIndex
                        val isActiveCol = caretCoords?.second == colIndex

                        Td({
                            style {
                                if (colIndex == 0 || (isActiveCol && isActiveRow)) position(Position.Relative)
                                background("transparent")
                            }
                        }) {
                            if (isActiveRow && isActiveCol) {
                                TableColMenu(dc, p, rowIndex, colIndex)
                            }
                            if (isActiveRow && colIndex == 0) {
                                TableRowMenu(dc, p, rowIndex, colIndex)
                            }
                            renderParagraph(p.elements[it] as Fragment.Paragraph, null, caret, null, dc, mode)
                        }
                    }
                }
            }
        }
    }
}