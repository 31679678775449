package editor.plugins

import document.*
import editor.Chain
import editor.views.Image
import editor.views.ImageFormat
import editor.insertBlockAndClean
import editor.operations.cleanBlock
import editor.operations.fixBlock
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.mp_tools.decodeBase64
import net.sergeych.mp_tools.encodeToBase64
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

external interface DOCXAttribute {
    val key: String
    val value: String?
}

external interface DOCXNode {
    val type: String;
}

enum class DOCXType(val jsType: String) {
    Element("element"),
    Comment("comment"),
    Text("text")
}

external interface DOCXElement: DOCXNode {
    val tagName: String
    val children: Array<DOCXNode>
    val attributes: Array<DOCXAttribute>
}

external interface DOCXComment: DOCXNode {
    val content: String
}

external interface DOCXText: DOCXNode {
    val content: String
}

suspend fun getGeometry(source: ByteArray): ImageFormat.Geometry = suspendCoroutine { continuation ->
    val image = org.w3c.dom.Image()

    image.onload = {
        val width = image.width.toFloat()
        val height = image.height.toFloat()
        image.remove()

        continuation.resume(
            ImageFormat.Geometry(
                width,
                height,
                0f, 0f,
                1f, 1f,
                0f
            )
        )
    }

    image.src = "data:image/png;base64, ${source.encodeToBase64()}"
}

fun parseA(el: DOCXElement, modifier: TextStyle = TextStyle()): Fragment? {
    val children = el.children.filter { it.type == "text" } as List<DOCXText>
    val text = children.map { it.content }.joinToString("")
    val attr = el.attributes.find { it.key == "href" }

    val href = attr?.let {
        if (attr.value?.startsWith("http") == true) attr.value else null
    }

    return Fragment.StyledSpan(text, modifier.withChild(TextStyle(underline = true)), href)
}

suspend fun parseTd(el: DOCXElement): Fragment.Paragraph {
//    val elements = el.children.filter { it.type == "element" } as List<DOCXElement>
    var content = el.children.map {
        val nodeContent = parseL0Node(it)
        Fragment.Paragraph(nodeContent).toFragment()
    }

    if (content.isEmpty()) content = listOf(Fragment.Paragraph(listOf(Fragment.StyledSpan(""))))

    return Fragment.Paragraph(elements = content)
}

suspend fun parseRow(el: DOCXElement): List<Fragment.Paragraph> {
    val elements = el.children.filter { it.type == "element" } as List<DOCXElement>
    val tds = elements.filter { it.tagName == "td" }

    return tds.map { parseTd(it) }
}

suspend fun parseTable(el: DOCXElement): Fragment.TableParagraph {
    val elements = el.children.filter { it.type == "element" } as List<DOCXElement>
    val rowElements = elements.filter { it.tagName == "tr" }
    val rows = rowElements.map { parseRow(it) }
    val rowsCount = rows.size

    val colsMax = rowElements.map { it.children.size }.max()
    val colsCount = colsMax

    val cells = mutableListOf<Fragment.Paragraph>()
    rows.forEach { row ->
        cells += row

        if (row.size < colsMax) {
            for (i in 0 until (colsMax - row.size)) cells += Fragment.TableParagraph.emptyCell()
        }
    }

    return Fragment.TableParagraph(cells, rowsCount=rowsCount, colsCount = colsCount)
}

suspend fun parseL0Paragraph(el: DOCXElement, modifier: TextStyle = TextStyle()): Fragment.Paragraph {
    val paragraphStyle = when(el.tagName) {
        "p" -> null
        "h1" -> ParagraphStyle.heading1
        "h2" -> ParagraphStyle.heading2
        "h3" -> ParagraphStyle.heading3
        "h4" -> ParagraphStyle.heading4
        else -> null
    }

    val elements = el.children.map { parseL0Node(it, modifier) }.flatten()

    return Fragment.Paragraph(elements, paragraphStyle)
}

suspend fun parseL0Node(el: DOCXNode, modifier: TextStyle = TextStyle()): List<Fragment> {
    return when(el.type) {
        "text" -> listOf(parseText(el as DOCXText, modifier))
        "element" -> parseL0Element(el as DOCXElement, modifier)
        else -> emptyList()
    }
}

suspend fun parseL0Element(el: DOCXElement, modifier: TextStyle = TextStyle()): List<Fragment> {
    return when(el.tagName) {
        "strong" -> {
            el.children.map { parseL0Node(it, modifier.withChild(TextStyle.bold)) }.flatten()
        }
        "em" -> {
            el.children.map { parseL0Node(it, modifier.withChild(TextStyle.italics)) }.flatten()
        }
        "u" -> {
            el.children.map { parseL0Node(it, modifier.withChild(TextStyle.underline)) }.flatten()
        }
        "p", "table", "tr", "td", "s", "sup", "sub" -> {
            el.children.map { parseL0Node(it, modifier) }.flatten()
        }
        "img" -> parseImg(el)?.let { listOf(it) } ?: emptyList()
        "a" -> parseA(el, modifier)?.let { listOf(it) } ?: emptyList()
        else -> {
            throw BugException("Can't parse L0 tag: ${el.tagName}")
        }
    }
}

suspend fun parseImg(el: DOCXElement): Fragment? {
    val src = el.attributes.find { it.key == "src" }?.value

    if (src == null) return null

    val prefix = "data:image/png;base64,"
    val bin = src.slice(prefix.length until src.length).decodeBase64()
    val geometry = getGeometry(bin)
    val img = Image(bin, geometry)
    val frame = Fragment.Frame("image", BossEncoder.encode(img))

    return frame
}

fun parseText(el: DOCXText, modifier: TextStyle = TextStyle()): Fragment {
    return Fragment.StyledSpan(el.content, modifier)
}

suspend fun parseList(el: DOCXElement, isOrdered: Boolean, level: Int = 0): List<Fragment.Paragraph> {
    val list = mutableListOf<Fragment.Paragraph>()
    val listStyle = if (isOrdered) ParagraphStyle.List.Numbers else ParagraphStyle.List.Bullets

    val elements = el.children.filter { it.type == "element" } as List<DOCXElement>
    val lis = elements.filter { it.tagName == "li" }
    val style = ParagraphStyle(indentLevel = level, listStyle = listStyle)

    fun appendTextParagraph(n: DOCXNode) {
        val t = n as DOCXText
        val elements = listOf(Fragment.StyledSpan(t.content))
        list += Fragment.Paragraph(elements, style)
    }

    suspend fun appendElement(n: DOCXNode) {
        val el = n as DOCXElement

        when(el.tagName) {
            "ol" -> list += parseList(el, true, level + 1)
            "ul" -> list += parseList(el, false, level + 1)
            else -> {
                val fragments = parseL0Element(el)
                list += Fragment.Paragraph(fragments, style)
            }
        }
    }

    lis.forEach {
        val children = it.children

        children.forEach {
            when(it.type) {
                "text" -> appendTextParagraph(it)
                "element" -> appendElement(it)
                else -> {}
            }
        }
    }

    return list
}

suspend fun parseIParagraph(el: DOCXElement): List<IParagraph> {
    return when(el.tagName) {
        "p", "h1", "h2", "h3", "h4" -> listOf(parseL0Paragraph(el))
        "table" -> listOf(parseTable(el))
        "ol" -> parseList(el, isOrdered = true)
        "ul" -> parseList(el, isOrdered = false)
        else -> {
            console.warn("Unknown tag at root level: ${el.tagName}")
            emptyList()
        }
    }
}

suspend fun importBlocks(docxJSON: Array<DOCXNode>): List<Block> {
    val elements = docxJSON.filter { it.type == "element" }
    if (elements.isEmpty()) return emptyList()

    val paragraphs = elements.map { parseIParagraph(it as DOCXElement) }.flatten()
    if (paragraphs.isEmpty()) return emptyList()

    val firstBlock = Block(paragraphs[0].makeCopy(paragraphStyle = paragraphs[0].paragraphStyle ?: ParagraphStyle.heading))
    val chain = Chain(listOf(cleanBlock(fixBlock(firstBlock)).block))

    for (i in 1 until paragraphs.size) {
        val block = fixBlock(Block(paragraphs[i]))
        chain.insertBlockAndClean(block, chain.last().guid)
    }

    return chain.elements
}