package editor.plugins

import document.Block
import editor.Chain
import editor.DebugList
import editor.DocContext
import editor.operations.*
import kotlinx.datetime.Instant
import net.sergeych.mptools.Now
import kotlin.time.Duration.Companion.seconds

suspend fun DocContext.undo() {
    replay.undo()

    runTransaction("UNDO",false) { chain, _ ->
        undoManager.undo()?.let {
            if (DebugList.undo) it.printCase("UNDO")
            chain.applyAUDTransaction(it)
        }
    }
}

suspend fun DocContext.redo() {
    replay.redo()

    runTransaction("REDO",false) { chain, _ ->
        undoManager.redo()?.let {
            if (DebugList.undo) it.printCase("REDO")
            chain.applyAUDTransaction(it)
        }
    }
}

class UndoManager(val dc: DocContext) {
    private var checkpointTime: Instant? = null
    private var commited = mutableListOf<RevertableTransaction<Block>>()
    private var reverted = mutableListOf<RevertableTransaction<Block>>()

    fun shouldCreate(force: Boolean): Boolean {
        val now = Now()

        val insertNew = force || checkpointTime?.let { ct ->
            now - ct > 2.seconds
        } ?: true

        if (insertNew) checkpointTime = now

        return insertNew
    }

    fun record(force: Boolean, t: RevertableTransaction<Block>) {
        reverted.clear()

        if (shouldCreate(force)) create(t) else append(t)
    }

    fun record(force: Boolean, chain: Chain<Block>) {
        record(force, chain.getTransaction())
    }

    private fun append(current: RevertableTransaction<Block>) {
        val previous = commited.lastOrNull() ?: return create(current)
        commited[commited.size - 1] = previous + current
    }

    private fun create(t: RevertableTransaction<Block>) {
        dc.replay.forceUndo()
        commited.add(t)
    }

    fun undo(): AUDTransaction<Block>? {
        return commited.removeLastOrNull()?.let { t ->
            reverted.add(t)
            t.undo
        }
    }

    fun redo(): AUDTransaction<Block>? {
        return reverted.removeLastOrNull()?.let { t ->
            commited.add(t)
            t.redo
        }
    }
}