package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import modalDialg
import net.sergeych.intecowork.api.ApiCodeActivationResult
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLInputElement

fun ModalCodeActivation(onDone: ((Boolean)->Unit)?=null) {
    modalDialg("Активировать скретч-код") {
        var onDoneCalled = false
        body {
            ServiceCodeForm {
                onDone?.invoke(it)
                onDoneCalled = true
                close()
            }
        }
        onClose {
            if( !onDoneCalled ) onDone?.invoke(false)
        }
    }
}

@Composable
fun ServiceCodeForm(setFocus: Boolean=true,onDone: ((Boolean)->Unit)?=null) {
    var code: String by remember { mutableStateOf("") }
    var error: String? by remember { mutableStateOf(null) }
    var inProgress: Boolean by remember { mutableStateOf(false) }
    var waitUntil: Instant? by remember { mutableStateOf(null) }
    val scope = rememberCoroutineScope()

    textField(code, "Секретный код", disabled = inProgress, id="codeInputField") { code = it.trim() }
    Div({
        style {
            fontSize(12.pt)
            marginTop((-6).pt)
        }
        classes("mb-1")
    }) {
        error?.let { emsg ->
            Txt(emsg, "text-danger")
        }
        waitUntil?.let {
            Text("следующая попытка возможна через ")
            RelativeTime(it) {
                waitUntil = null
            }
        }
    }
    Btn(
        disabled = code.isEmpty() || inProgress || waitUntil != null,
        content = {
            if (inProgress) {
                Div({ classNames("spinner-border spinner-border-sm d-inline-block me-2") })
                Text("проверка кода...")
            } else {
                Icon.SendCheck.render({ classes("me-2") })
                Text("активировать")
            }
        }
    ) {
        scope.launch {
            inProgress = true
            error = null
            try {
                val result = client.call(client.api.activateSecretCode, code)
                when (result) {
                    is ApiCodeActivationResult.AlreadyActivated -> error = "Вы уже активировали этот код"
                    ApiCodeActivationResult.InvalidCode -> error = "неверный код"
                    is ApiCodeActivationResult.Success -> {
                        Toaster.info("Код успешно активирован")
                        code = ""
                        onDone?.invoke(true)
                    }

                    is ApiCodeActivationResult.TryAgainLater -> waitUntil = result.after
                }
            }
            catch(t: Throwable) {
                error = t.toString()
            }
            finally {
                inProgress = false
            }
        }
    }
    if( setFocus ) {
        LaunchedEffect(true) {
            delay(600)
            window.document.getElementById("codeInputField")?.let {
                (it as? HTMLInputElement)?.focus()
            }
        }
    }
}
