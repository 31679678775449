/*
 * Copyright 2009-2017 java-diff-utils.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package dev.gitlive.difflib.patch

/**
 * Describes the add-delta between original and revised texts.
 *
 * @author [Dmitry Naumenko](dm.naumenko@gmail.com)
 * @param <T> The type of the compared elements in the 'lines'.
</T> */
class InsertDelta<T>
/**
 * Creates an insert delta with the two given chunks.
 *
 * @param original The original chunk. Must not be `null`.
 * @param revised The original chunk. Must not be `null`.
 */
    (original: Chunk<T>, revised: Chunk<T>) : AbstractDelta<T>(DeltaType.INSERT, original, revised) {
    //    @Throws(PatchFailedException::class)
    protected override fun applyTo(target: MutableList<T>) {
        val position = source.position
        val lines: List<T> = this.target.lines
        for (i in lines.indices) {
            target.add(position + i, lines[i])
        }
    }

    override fun restore(target: MutableList<T>) {
        val position = this.target.position
        val size = this.target.size()
        for (i in 0 until size) {
            target.removeAt(position)
        }
    }

    override fun toString(): String {
        return ("[InsertDelta, position: " + source.position
                + ", lines: " + target.lines + "]")
    }

    override fun withChunks(original: Chunk<T>, revised: Chunk<T>): AbstractDelta<T> {
        return InsertDelta(original, revised)
    }

    /**
     * Eliminates inserting the same text in the same positions
     */
    override fun optimizeAgainst(existing: AbstractDelta<T>): AbstractDelta<T>? {
        return super.optimizeAgainst(existing)?.let { x ->
            if (existing !is InsertDelta)
                x
            else {
                // this algorithm only eliminates deltas that have the common prefix:
                if (existing.target.position != target.position)
                    return this
                // remove common prefix:
                var offset = 0
                while (existing.target.lines[offset] == target.lines[offset]) {
                    offset++
                    if (offset >= target.lines.size || offset >= existing.target.lines.size) break
                }
                if (offset >= target.lines.size) {
                    // removed completely
                    return null
                }
                // removed a part:
                val tail = target.lines.drop(offset)
                return InsertDelta(
                    Chunk(source.position, source.lines.drop(offset), source.changePosition?.let { it + offset }),
                    Chunk(target.position + offset, tail, target.changePosition?.let { it + offset })
                )
            }
        }
    }
}