package views

import AcceptManager
import androidx.compose.runtime.*
import controls.Btn
import controls.Di
import controls.Txt
import controls.Variant
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.ApiAgreement
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text

@Composable
fun RequireAction(action: ApiAgreement.Action, f: @Composable ()->Unit) {
    var result: Boolean? by remember { mutableStateOf(null) }
    val scope = rememberCoroutineScope()

    fun checkAcceptances() {
        scope.launch {
            try {
                result = null
                result = AcceptManager.checkAction(action)
                console.log("AMRES: $result")
            }
            catch(t: Throwable) {
                t.printStackTrace()
            }
        }
    }

    when(result) {
        null -> f() //WaitMessage("ожидание готовности: ${action.text}")
        false -> {
            Di("container mt-5") {
                Di("alert alert-warning") {
                    H1 { Text("Доступ запрещен") }
                    Txt("""
                        Вы не согласились с условиями как минимум одного лицензионного соглашения,
                        которое необходимо для доступа "${action.text}.
                        Чтобы получить доступ к запрошенной функции вам необходимо принять все
                        требуемые лицензионные соглашения
                    """.trimIndent(), mt=2)
                    Di("text-center mt-4") {
                        Btn("открыть соглашения", variant = Variant.Primary) {
                            AcceptManager.retryRejected(action)
                            checkAcceptances()
                        }
                    }
                }
            }
        }
        true -> f()
    }

    LaunchedEffect(true){
        checkAcceptances()
    }

}