package editor.operations

import document.Block
import document.BugException
import document.Fragment
import editor.*
import editor.views.StyledSpanCSS
import kotlinx.browser.window
import kotlinx.dom.hasClass
import kotlinx.dom.isText
import org.w3c.dom.Node
import org.w3c.dom.get

fun DocContext.getClosestBlockDOM(point: Point): Block? {
    val yBlockDistances = mutableMapOf<String, Double>()
    doc.allBlocks.map { block ->
        BlockBox(block)?.let {
            yBlockDistances[block.guid] = it.yDistanceTo(point)
        }
    }
    val closestYDistance = yBlockDistances.minOfOrNull { it.value }
    val closestYBlocks = doc.allBlocks.filter { yBlockDistances[it.guid] == closestYDistance }
    val xBlockDistances = mutableMapOf<String, Double>()
    closestYBlocks.map { block ->
        BlockBox(block)?.let {
            xBlockDistances[block.guid] = it.distanceTo(point)
        }
    }

    val closestBlock = xBlockDistances.minByOrNull { it.value }
//    console.log("access to doc 1")
    return closestBlock?.let { doc[it.key] }
}

data class FragmentBox(val fragment: Fragment, val node: Node, val box: Box)

/**
 * Get all bounding boxes for all #text nodes.
 *
 * Get all divs, get all text nodes from them (removing carets and whatever else
 * no connected to the editable text) and return it with its bounding rect
 */
fun Block.textBoundingBoxesDOM(): List<FragmentBox> {
    val result = mutableListOf<FragmentBox>()

    val bodyOrigin = bodyOrigin()

    for (f in fragments) {
        val elem = window.document.getElementById(f.guid)
            ?: throw BugException("failed to find fragment's element for $guid")
        if (elem.hasClass((StyledSpanCSS))) {
            // now extract nodes:
            for (i in 0 until elem.childNodes.length) {
                elem.childNodes.get(i)?.let { node ->
                    if (node.isText)
                        result += elem.getClientRects().map {
                            FragmentBox(f, node, Box(it) - bodyOrigin)
                        }
                }
            }
        }
    }
    return result
}