package editor.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import controls.Txt
import document.*
import editor.*
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

val StyledSpanCSS = "_sspan"

@Composable
fun VisibleText(text: String) {
    if (text.trim() != "") {
        Text(text)
    } else {
        val visibleText = text.map { if (it == ' ') "${Typography.nbsp}" else it }.joinToString("")

        Text(visibleText)
    }
}

@Composable
fun renderStyledSpan(
    fragment: Fragment.StyledSpan,
    attrBuilderContext: AttrBuilderContext<HTMLDivElement>?,
    caret: Caret?,
    styspanStyle: TextStyle?,
    dc: DocContext,
    mode: RenderMode,
    paragraphStyle: ParagraphStyle? = null
) {
    SideEffect {
        dc.selection.showDOM()
    }
    val isLink = fragment.isLink()

    val caretVersion = dc.domObserver.getVersion(CaretId)


    if (mode == RenderMode.DOCX && fragment.isVisible) {
        val style = (styspanStyle?.withChild(fragment.textStyle) ?: fragment.textStyle) ?: TextStyle()
        if (isLink) A(href = fragment.href ?: "", {
            style {
                style.fontFamily?.let { fontFamily(it.cssFamily) }
                style.fontWeight?.let { fontWeight(it.cssWeight) }
                style.fontSize?.let { fontSize(it.pt) }
                property("color", "#0d6efd")
                style.backgroundColor?.let { backgroundColor(it) }
//                style.italics?.let { fontStyle("italic")}
//                style.underline?.let { textDecoration("underline")}
            }
        }) { ensureI(style.italics ?: false) { Text(fragment.text) } }

        else Span({
            style {
                style.fontFamily?.let { fontFamily(it.cssFamily) }
                style.fontWeight?.let { fontWeight(it.cssWeight) }
                style.fontSize?.let { fontSize(it.pt) }
                if (isLink) {
                    property("color", "#0d6efd")
                } else {
                    style.textColor?.let { color(it) }
                }
                style.backgroundColor?.let { backgroundColor(it) }
//                style.italics?.let { fontStyle("italic")}
//                style.underline?.let { textDecoration("underline")}
            }
        }) {
            ensureI(style.italics ?: false) {
                ensureU((style.underline ?: false) || fragment.isLink()) {
                    Text(fragment.text)
                }
            }
        }
    } else {

        Div({
            style {
                display(DisplayStyle.Inline)
            }
            classes(StyledSpanCSS)
            styspanStyle?.effectiveStyle?.buildAttributes(this)
            fragment.textStyle?.buildAttributes(this)
            id(fragment.guid)
            attr("data-ftype", "sspan")
            attrBuilderContext?.invoke(this)

            if (isLink) {
                style {
                    property("color", "#0d6efd")
                    textDecoration("underline")
                    cursor("pointer")
                }
            }

            if (isLink) onClick { window.open(fragment.href ?: "") }
        }) {

            if (fragment.isVisible) {
                if (fragment.text == "") {
                    caret?.let { ct ->
                        if (ct in fragment) {
                            renderCaret(dc, mode, caretVersion)
                        }
                    }
                    // We need this for correct yRange detection
                    Text("${Typography.nbsp}")
                } else
                    caret?.let { ct ->
                        if (ct in fragment) {
//                        console.log("caret in between '${fragment.text.substring(0 until ct.offset)}' and '${fragment.text.substring(ct.offset)}'")
                            fragment.text.substring(0 until ct.offset).let {
                                if (it.isNotEmpty()) {
                                    // FIXME: remove invisible from doc
                                    if (it.last() != invisibleNBSP) Text(it + invisibleNBSP)
                                    else Text(it)
                                }
                            }
                            renderCaret(dc, mode, caretVersion)
                            fragment.text.substring(ct.offset).let {
                                if (it.isNotEmpty()) {
                                    // FIXME: remove invisible from doc
                                    if (it.first() != invisibleNBSP) Text(invisibleNBSP + it)
                                    else Text(it)
                                }
                            }
                        } else {
                            VisibleText(fragment.text)
                        }
                    } ?: VisibleText(fragment.text)
                dc.doc.userHint()?.let {
                    when (it) {
                        Doc.Hint.NoTitle -> Txt("дайте документу хорошее название", "text-muted d-inline")
                        Doc.Hint.NoBody -> {
                            if (fragment.isBlank)
                                Txt("начинайте ввод документа", "text-muted d-inline")
                        }
                    }
                }
            }
        }
    }
}