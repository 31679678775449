package controls

import Browser
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.browser.document
import kotlinx.coroutines.delay
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import tools.randomId
import tools.trimMiddle


@Composable
fun Copyable(toShow: String, toCopy: String = toShow,maxLength: Int=17) {
    val ttId = remember { randomId(5) }
    Span({
        style {
            cursor("pointer")
            property("color", "navy")
            property("border-bottom", "1px dashed")
            whiteSpace("nowrap")
            position(Position.Relative)
        }
        id(ttId)
        onClick {
            @Suppress("UNUSED_VARIABLE") val e = document.getElementById(ttId)
            js(
                "e = bootstrap.Tooltip.getOrCreateInstance(e, { title: 'скопировано', trigger: 'manual' })"
            )
            globalLaunch {
                Browser.clipboardWriteText(toCopy)
                js("e.show()")
                delay(1500)
                js("e.hide()")
            }
        }
    }) {
        Text(toShow.trimMiddle(maxLength))
        Icon.ClipboardPlus.render({
            style {
                position(Position.Relative)
                display(DisplayStyle.Inline)
                padding(0.px)
                marginBottom(0.px)
                marginLeft(2.px)
                marginTop(0.px)
            }
        })
    }
}
