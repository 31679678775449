package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.document
import kotlinx.coroutines.launch
import net.sergeych.intecowork.api.ApiUser
import net.sergeych.intecowork.api.ApiUserDetails
import net.sergeych.intecowork.api.ErrorCode
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.Passwords
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement

@Composable
fun Registration(additionalContent: ContentBuilder<HTMLDivElement>) {
    var loginName by remember { mutableStateOf("testUser") }
    var publicName by remember { mutableStateOf("test user") }
    var approvedPublicName by remember { mutableStateOf("") }
    var loginMessage by remember { mutableStateOf<String?>(null) }
    var codeMessage by remember { mutableStateOf<String?>(null) }
    var nickMessage by remember { mutableStateOf<String?>(null) }
    var nickOk by remember { mutableStateOf<Boolean?>(null) }
    var code by remember { mutableStateOf("RDS-2") }
    var loginOk by remember { mutableStateOf<Boolean?>(null) }
    var password1 by remember { mutableStateOf("12345qwert") }
    var password1Message by remember { mutableStateOf("") }
    var password1Ok by remember { mutableStateOf<Boolean?>(null) }
    var password2 by remember { mutableStateOf("12345qwert") }
    var password2Ok by remember { mutableStateOf<Boolean?>(null) }
    var inProgress by remember { mutableStateOf(false) }

    val scope = rememberCoroutineScope()

    fun checkLogin() {
        when {
            loginName.trim() != loginName -> {
                loginOk = false
                loginMessage = "не должно содержать пробелов в начале и конце"
            }

            loginName.isEmpty() -> {
                loginOk = false
                loginMessage = "требуемое поле"

            }

            loginName.length < 3 -> {
                loginOk = false
                loginMessage = "слишком короткое (${loginName.length}"

            }

            loginName[0] == '.' -> {
                loginOk = false
                loginMessage = "не должно начинаться с точки"
            }

            else -> {
                loginOk = null
                loginMessage = null
                scope.launch {
                    println("Start check login")
                    loginOk = client.isLoginNameAvailable(loginName)
                    println("end check login: $loginOk")
                    loginMessage = if (loginOk == false) "недоступное имя" else null
                }
            }
        }
    }

    fun checkNick(nick: String,checkAvailable: Boolean=true) {
        nickOk = null
        nickMessage = "проверяем"
        ApiUser.checkName(nick)?.let {
            nickMessage = it
            nickOk = false
            return
        }
        if( checkAvailable ) {
            scope.launch {
                try {
                    val nick2 = client.call(client.api.getAvailableNick, nick)
                    if( nick2 != publicName ) {
                        publicName = nick2
                        nickMessage = "замена предложена сервером"
                    }
                    else
                        nickMessage = ""
                    approvedPublicName = nick2
                    nickOk = true
                } catch (e: Exception) {
                    nickMessage = "не удается проверить"
                }
            }
        }
        else nickMessage = ""
    }

    fun checkPasswords() {
        val strength = Passwords.estimateBitStrength(password1)
        when {
            password1.isEmpty() -> {
                password1Ok = false
                password1Message = "требуемое поле"
            }

            strength < 32 -> {
                password1Ok = false
                password1Message = "слишком слабый пароль (~$strength бит)"
            }

            else -> {
                password1Ok = true
                password1Message = "оценочная стойкость $strength бит"
            }
        }
        password2Ok = password1Ok == true && password1 == password2
    }

    LaunchedEffect(true) {
        checkLogin()
        checkNick(publicName)
        checkPasswords()
    }

    Div {
        H3 { Text("Создание учетной записи:") }
        val loginId = textField(
            loginName,
            "Логин для входа",
            isValid = loginOk,
            message = loginMessage
        ) {
            loginName = it
            checkLogin()
        }
        // Hide password popup
        Input(InputType.Password) {
            style { display(DisplayStyle.None) }
        }
        textField(
            publicName,
            "Имя, видимое для других пользователей",
            message = nickMessage,
            isValid = nickOk,
            autocomplete = false,
            id = "publicName",
            onBlur = { checkNick(publicName,true)}
        ) {
            if (publicName != it) {
                publicName = it
                println("publicName: $publicName approvedPublicName: $approvedPublicName")
                if (approvedPublicName != it)
                    checkNick(it,false)
            }
        }
        textField(
            password1,
            "Пароль",
            type = InputType.Password,
            isValid = password1Ok,
            message = password1Message
        ) {
            password1 = it
            checkPasswords()
        }
        textField(
            password2,
            "Повторно пароль",
            type = InputType.Password,
            isValid = password2Ok,
            message = if (password2Ok == false && password1.isNotBlank()) "не совпадают" else null
        ) {
            password2 = it
            checkPasswords()
        }
        textField(
            code,
            "Код активации",
            type = InputType.Text,
            isValid = codeMessage == null,
            message = codeMessage
        ) {
            code = it
        }
        Bn({
            classNames("btn-primary me-2")
            if (inProgress || publicName.isBlank() || loginOk == false || password1Ok == false
                || password2Ok != true || nickOk != true)
                disabled()
            onClick {
                inProgress = true
                globalLaunch {
                    val rr = client.register(
                        ApiUserDetails(
                            loginName = loginName, name = publicName
                        ),
                        if (code.isNotBlank()) code else null,
                        password1,
                        mainKeyStrength = 4096,
                        loginKeyStrength = 4096
                    )
                    inProgress = false
                    if (rr.isOk) {
                        Toaster.info("Успешная регистрация")
                        // todo: show guid
                        ShowSecret(rr.resultOrThrow())
                    } else {
                        loginMessage = null
                        when {
                            ErrorCode.LoginInUse in rr -> {
                                loginOk = false
                                loginMessage = "Имя недоступно"
                            }

                            ErrorCode.CodeIsRequired in rr -> codeMessage = "Для регистрации требуется код"
                            ErrorCode.NickInUse in rr -> nickMessage = "Имя пользователя уже использовано"
                            ErrorCode.CodeIsInvalid in rr -> codeMessage = "неправильный или неподходящий код"
                            ErrorCode.UnknownError in rr ->
                                Toaster.error("ошибка при регистрации, попробуйте еше раз позже")
                        }
                    }
                    inProgress = false
                }
            }
        }) {
            if (inProgress) {
                Di("spinner-border spinner-border-sm d-inline-block me-1") {}
            }
            Text("Регистрация")
        }
        additionalContent()
        LaunchedEffect("registrationFocus") {
            (document.getElementById(loginId) as? HTMLInputElement)?.focus()
        }
    }
}