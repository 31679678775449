package editor.operations

import document.*
import editor.*
import tools.randomId

fun styleMatcher(style: ParagraphStyle?): ParagraphStyle? {
    return when(style?.defaultTextStyle) {
        TextStyle.heading -> ParagraphStyle.subheading
        TextStyle.subheading -> ParagraphStyle.normal
        TextStyle.heading1, TextStyle.heading2, TextStyle.heading3, TextStyle.heading4 -> ParagraphStyle.normal
        else -> style
    }
}

class ContentSplitter(val block: Block, val caret: Caret) {
    private var path = caret.path

    data class SplitResult(
        val before: Fragment,
        val after: Fragment,
    )

    fun split(): SplitResult = split(block.paragraph as Fragment)

    private fun split(f: Fragment, index: Int = 1): SplitResult {
        when (f) {
            is Fragment.StyledSpan -> {
                if (f.guid != caret.spanId)
                    throw BugException("span fragment is not at the end of caret span, can split to children")
                val left = f.copy(f.text.substring(0, caret.offset))
                val right = Fragment.StyledSpan(f.text.substring(caret.offset), textStyle = f.textStyle, href = f.href)
                return SplitResult(left, right)
            }

            is Fragment.Paragraph -> {
                // we need to create a left-part of the paragraph, before split point:
                val before = mutableListOf<Fragment>()
                val after = mutableListOf<Fragment>()
                var isBefore = true
                for (x in f.elements) {
                    if (x.guid == path[index]) {
                        // this one we need to split
                        val r = split(x, index+1)
                        before.add(r.before)
                        after.add(r.after)
                        isBefore = false
                    } else {
                        // this one we can just copy
                        if( isBefore ) before += x else after += x
                    }
                }

                return SplitResult(
                    f.copy(before),
                    f.copy(after, guid = randomId(11), paragraphStyle = styleMatcher(f.paragraphStyle))
                )
            }

            is Fragment.TableParagraph -> {
                val cell = f.find(path[index]) ?: throw BugException("Can't find caret cell")
                if (cell !is Fragment.Paragraph) throw BugException("Cell is not paragraph")
                val p = cell.find(path[index + 1]) as Fragment.Paragraph?
                    ?: throw BugException("Cell has no nested paragraph")

                return split(p, index + 2)
            }
            else ->
                throw BugException("can't split fragment of this type: $f")
        }
    }

}

fun Chain<Block>.splitTableCellParagraph(atCaret: Caret? = null): String {
    val logger = log("splitTableCellParagraph")
    logger("run")
    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    var block = caretBlock(c)
    var cell = block.find(c.path[1]) as Fragment.Paragraph? ?: throw BugException("Can't find cell by caret")
    val cellP = cell.find(c.path[2]) as Fragment.Paragraph? ?: throw BugException("Can't find cell paragraph by caret")

    val split = ContentSplitter(block, c).split()

    if (split.before !is IParagraph) throw BugException("split.before has wrong type: $split")
    if (split.after !is IParagraph) throw BugException("split.after has wrong type: $split")

    val leftPIndex = cell.elements.indexOf(cellP)

    val cellElements = cell.elements.slice(0 until leftPIndex).toMutableList()

    cellElements += split.before
    cellElements += split.after

    cellElements += cell.elements.slice((leftPIndex + 1) until cell.elements.size)
    cell = cell.copy(elements = cellElements)
    block = block.withUpdatedFragment(cell)
    val firstText = split.after.firstTextFragment()
    val caretAfter = block.caretAt(firstText.guid, 0)
    updateBlockAndClean(block, caretAfter)

    return split.after.guid
}

fun Chain<Block>.splitParagraph(atCaret: Caret? = null): String {
    val logger = log("splitParagraph")
    logger("run")
    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val split = ContentSplitter(block, c).split()

    if (split.before !is IParagraph)
        throw BugException("split.before has wrong type: $split")
    if (split.after !is IParagraph)
        throw BugException("split.after has wrong type: $split")

    val leftBlock = block.copy(paragraph = split.before)
    val rightBlock = Block(split.after)

    caret = rightBlock.caretAtStart()

    updateBlockAndClean(leftBlock)
    insertBlockAndClean(rightBlock, leftBlock.guid)

    logger("done")
    return rightBlock.guid
}

fun Chain<Block>.split(atCaret: Caret? = null): String {
    val logger = log("split")
    logger("run")
    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    val rightParagraphId = when(c.rootType) {
        CaretRoot.TABLE -> splitTableCellParagraph(c)
        CaretRoot.PARAGRAPH -> splitParagraph(c)
        CaretRoot.TEMPORARY -> throw BugException("Can't use temporary caret in operations")
    }

    return rightParagraphId
}

suspend fun DocContext.splitBlockAtCaret() {
    replay.splitBlockAtCaret()

    runTransaction("SPLIT AT CARET", shouldForceRecord = true) { chain, style ->
        chain.split()
    }
}